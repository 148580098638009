<template>
  <div>
  <header class="header">
    <nav class="d-flex" style="align-items: center; justify-content: space-between; margin-top: -20px;">
      <img src="@/assets/dermapp-white.webp" alt="Logo" class="logo" style="height:75px;"/>
      <div>
        <button class="signin-btn" @click="goToGuestAssessment()" style="margin-right: 30px;">Consultar ahora</button>
        <button v-if=!isMobile() class="signin-btn" @click="goToSignIn()" style="margin-right: 30px;">Iniciar sesion</button>
        </div>

    </nav>
  </header>

  <section v-if="!isMobile()" class="section section-1" id="section-1">
<div class="content">
  <div class="column left-column">
    <img src="@/assets/dermapp-white.webp" alt="Logo" class="logo-column" style="width:35vw;height:auto;margin-bottom:-100px;margin-top:-80px"/>
    <p style="font-size:28px; color:white">Consulta con dermatólogos certificados</p>
      <strong style="font-size:38px; color: white; margin-bottom:30px">Tu primera consulta<br>por sólo 180 MXN</strong>      
      <button class="cta-button" @click="goToGuestAssessment()">Consultar ahora</button>
    <strong style="font-size:28px; color: white; margin-bottom:30px; margin-top:10px">Obtén un plan de tratamiento<br>personalizado en menos de 24 horas</strong>
    <div class="d-flex" style="margin-left:-200px">
      <div>
        <p style="font-size:20px; color:white; text-align:left; margin-bottom:30px" class="text-white">Aprobado por:</p>
        <img src="@/assets/landing2.webp" alt="Cofepris y FDA" class="mobile-logo-column" style="width: 20vw; height: auto; margin-bottom: -30px; margin-top: -60px;"/>
      </div>
      <div>
      </div>
    </div>
    
  </div>
  <div class="column right-column">
    <div>
      <img src="@/assets/landing1.webp" alt="mobile-Logo" class="mobile-logo-column" style="width: 30vw; height: auto; margin-bottom: -30px; margin-top:20px"/>
  </div>
  <div>
      <button class="cta-button" style="margin-top: 60px;" @click="goToSignIn()">Iniciar sesión</button>
    </div>
  </div>
</div>
</section>
<section v-if="isMobile()" class="section section-1" id="section-1">
  <div class="content">
    <div style="margin-left: 20px;">
      <div>
        <img src="@/assets/landing1.webp" alt="mobile-Logo" class="mobile-logo" style="width: 30vw; height: auto; margin-bottom: -30px"/>
    </div>
    <div>
        <img src="@/assets/dermapp-white.webp" alt="mobile-Logo" class="mobile-logo-column" style="width: 90vw; height: auto; margin-bottom: -60px;"/>
    </div>
    <div style="margin-bottom:10px;">
        <p style="font-size:18px" class="text-white">Consulta con dermatólogos certificado</p>
    </div>
    <div style="margin-bottom:10px;">
        <strong style="font-size:28px" class="text-white">Tu primera consulta<br>por sólo 180 MXN</strong>
    </div>
    <div style="margin-bottom:10px">
        <button class="cta-button-mobile" @click="goToGuestAssessment">Consultar ahora</button>
    </div>
    <div style="margin-bottom:10px">
      <p style="font-size:18px" class="text-white">Obtén un plan de tratamiento<br>personalizado en menos de 24 horas</p>
    </div>
    <div style="margin-bottom:10px">
        <button class="cta-button-mobile" @click="goToSignIn">Iniciar sesión</button>
    </div>
    <div style="margin-bottom:10px">
      <p style="font-size:18px" class="text-white">Aprobado por:</p>
    </div>
    <div>
        <img src="@/assets/landing2.webp" alt="Cofepris y FDA" class="mobile-logo-column" style="width: 30vw; height: auto; margin-bottom: -30px; margin-top: -60px;"/>
    </div>
    </div>
    
  </div>
</section>

<section v-if="!isMobile()" class="section section-3" id="section-3">
<div class="content">
  <div class="col">
    <div class="row d-flex" style="text-align:left;">
      <strong style="font-size:102px" class="text-white">Disponible 24 / 7</strong>
      <p style="font-size:38px;margin-top:-20px" class="text-white">Desde cualquier parte de México, Latinoamérica o Estados Unidos</p>
    </div>
  <div class="row" style="margin-top:50px">
    <div class="col" style="width:30%">
      <img src="@/assets/landing3.webp" alt="Problem Icon" style="width:15vw;height: auto;">
      <p style="font-size: 28px;color:white; margin-top:45px">Comparte tu<br> problema</p>
    </div>
    <div class="col" style="width:30%">
      <img src="@/assets/landing4.webp" alt="Photos Icon" style="width:15vw;height: auto;">
      <p style="font-size: 28px; color:white; margin-top:20px" >Comparte<br> tus fotos</p>
    </div>
    <div class="col" style="width:30%">
      <img src="@/assets/landing5.webp" alt="Treatment Icon" style="width:15vw;height: auto;">
      <p style="font-size: 28px; color:white; margin-top:20px">Obtén tu plan<br> de tratamiento</p>
    </div>
  </div>
      
</div>
</div>
</section>
<section v-else class="section section-3" id="section-3">
<div class="content" style="justify-content: center">
  <div>
    <div style="margin-bottom:10px;">
      <strong style="font-size:28px" class="text-white">Disponible 24/7</strong>
  </div>
  <div style="margin-bottom:30px;">
      <p style="font-size:24px" class="text-white">En México, LATAM y Estados Unidos</p>
  </div>
  <div style="margin-bottom:30px;">
        <img src="@/assets/landing3.webp" alt="Problem Icon" style="width:30vw;height: auto; margin-bottom: 20px;">
    <p style="font-size: 18px;color:white">1. Comparte <br>tu problema</p>
  </div>
  <div class="d-flex" style="justify-content: space-between;">
    <div style="margin-right: 20px;">
    <img src="@/assets/landing4.webp" alt="Photos Icon" style="width:30vw;height: auto; margin-bottom: 20px;">
    <p style="font-size: 18px; color:white">2. Comparte <br>tus fotos</p>

    </div>
    <div>
      <img src="@/assets/landing5.webp" alt="Treatment Icon" style="width:30vw;height: auto; margin-bottom: 20px;">
      <p style="font-size: 18px; color:white">3. Obtén tu plan<br> de tratamiento</p>
    </div>   
  </div>
  </div> 
</div>
</section>


<section v-if="!isMobile()" class="section section-5" id="section-5">
<div class="content">
  <div class="col">
    <div class="row d-flex" style="text-align:left;margin-top:100px">
      <strong style="font-size:102px;" class="text-white">Dile adiós al Acné</strong>
      <p style="font-size:38px;margin-top:-20px" class="text-white">Suscríbete desde 75 MXN al mes</p>
    </div>
  <div class="row" style="margin-top:-258px">
    <div class="col" style="width:100%; margin-left:-50px">
      <img src="@/assets/landing15-2.webp" alt="Treatment Icon" style="width:100vw;height: auto;">
    </div>
    <div>
      <p style="color:white; margin-top:-240px; font-size:38px; text-align:left" >Toda la atención que tu piel necesita por el precio de una consulta tradicional</p>
    </div>
  </div>
</div>
</div>
</section>
<section v-else class="section section-1" id="section-4">
<div class="content" style="justify-content: center">
  <div>
    <div style="margin-bottom:30px;margin-top:120px">
      <strong style="font-size:28px" class="text-white">Dile adiós al Acné</strong>
  </div>
  <div style="margin-bottom:50px;">
      <p style="font-size:24px" class="text-white">Toda la atención que tu piel<br> necesita por el precio de una consulta tradicional</p>
  </div>
  <div style="margin-bottom:5px">
      <button class="cta-button-mobile" @click="goToSignIn()">Consultar ahora</button>
  </div>
  <div style="margin-bottom:-105px;">
        <img src="@/assets/landing7-4.webp" alt="skin Icon" style="width:100vw;height: auto; margin-top: 30px;">
  </div>
  <div style="margin-bottom:100px;">
      <p style="font-size:18px" class="text-white">Suscríbete desde 75 MXN al mes</p>
  </div>
  </div>
</div>
</section>

<section v-if="!isMobile()" class="section section-3" id="section-5">
<div class="content">
  <div class="col">
    <div class="row d-flex" style="text-align:left;">
      <strong style="font-size:102px;" class="text-white">Elige cuanto gastar</strong>
      <p style="font-size:38px;margin-top:-20px" class="text-white">Crearemos un plan de tratamiento con opciones a tu medida</p>
    </div>
  <div class="row" style="margin-top:50px">
    <div class="col" style="width:30%;">
      <img src="@/assets/landing10.webp" alt="Problem Icon" style="width:10vw;height: auto;">
      <p style="font-size: 28px;color:white;margin-top:45px">Comparte tu<br> presupuesto</p>
    </div>
    <div class="col" style="width:30%">
      <img src="@/assets/landing9.webp" alt="Photos Icon" style="width:10vw;height: auto;">
      <p style="font-size: 28px; color:white;margin-top:20px">Tratamiento mínimo<br> indispensable</p>
    </div>
    <div class="col" style="width:30%">
      <img src="@/assets/landing8.webp" alt="Treatment Icon" style="width:10vw;height: auto;">
      <p style="font-size: 28px; color:white;margin-top:60px">Tratamiento óptimo<br>extendido</p>
    </div>
  </div>
      
</div>
</div>
</section>
<section v-else class="section section-3" id="section-5">
<div class="content" style="justify-content: center">
  <div>
    <div style="margin-bottom:10px;">
      <strong style="font-size:28px" class="text-white">Elige cuanto gastar</strong>
  </div>
  <div style="margin-bottom:30px;">
      <p style="font-size:24px" class="text-white">Crearemos un plan con opciones a tu medida</p>
  </div>
  <div style="margin-bottom:30px;">
        <img src="@/assets/landing10.webp" alt="Problem Icon" style="width:30vw;height: auto; margin-bottom: 20px;">
    <p style="font-size: 18px;color:white">Comparte tu<br> presupuesto</p>
  </div>
  <div class="d-flex" style="justify-content: space-between;">
    <div style="margin-right: 20px;">
    <img src="@/assets/landing9.webp" alt="Photos Icon" style="width:30vw;height: auto; margin-bottom: 20px;">
    <p style="font-size: 18px; color:white">Tratamiento mínimo</p>

    </div>
    <div>
      <img src="@/assets/landing8.webp" alt="Treatment Icon" style="width:30vw;height: auto; margin-bottom: 22px; margin-top: 30px;">
      <p style="font-size: 18px; color:white">Tratamiento extendido</p>
    </div>   
  </div>
  </div> 
</div>
</section>



<section v-if="!isMobile()" class="section section-5" id="section-5">
<div class="content">
  <div class="col">
    <div class="row d-flex" style="text-align:left;margin-top:88px">
      <strong style="font-size:102px;" class="text-white">Celebra tu piel</strong>
      <p style="font-size:38px;margin-top:-20px" class="text-white">La salud de la piel no debe ser un lujo</p>
    </div>
  <div class="row" style="margin-top:0px">
    <div class="col" style="width:100%; margin-left:-50px">
      <img src="@/assets/landing16.webp" alt="Treatment Icon" style="width:100vw;height: auto;">
    </div>
  </div>
</div>
</div>
</section>
<section v-else class="section section-1" id="section-6">
<div class="content" style="justify-content: center">
  <div>
    <div style="margin-bottom:30px;margin-top:25px">
      <strong style="font-size:28px" class="text-white">Dile adiós a las manchas</strong>
  </div>
  <div style="margin-bottom:50px;">
      <p style="font-size:24px" class="text-white">La salud de la piel no<br> debe ser un lujo</p>
  </div>
  <div style="margin-bottom:30px">
      <button class="cta-button-mobile" @click="goToSignIn()">Consultar ahora</button>
  </div>
  <div style="margin-bottom:50px;">
      <p style="font-size:18px" class="text-white">Celebra tu piel con dermapp</p>
  </div>
  <div style="margin-bottom:-105px;">
        <img src="@/assets/landing11.webp" alt="skin Icon" style="width:100vw;height: auto; margin-top: 30px;">
  </div>
  </div>
</div>
</section>

<section v-if="!isMobile()" class="section section-3" id="section-5">
<div class="content">
  <div class="col">
    <div class="row d-flex" style="text-align:left;">
      <strong style="font-size:102px;" class="text-white">Ahorra con dermapp</strong>
      <p style="font-size:38px;margin-top:-20px" class="text-white">Acumula puntos y obtén descuentos en consultas y productos</p>
    </div>
  <div class="row" style="margin-top:50px">
    <div class="col" style="width:30%;">
      <img src="@/assets/landing12.webp" alt="Problem Icon" style="width:10vw;height: auto;">
      <p style="font-size: 28px;color:white;margin-top:45px">Cashback para<br> suscriptores</p>
    </div>
    <div class="col" style="width:30%">
      <img src="@/assets/landing13-2.webp" alt="Photos Icon" style="width:10vw;height: auto;">
      <p style="font-size: 28px; color:white;margin-top:20px">Completa las<br> misiones</p>
    </div>
    <div class="col" style="width:30%">
      <img src="@/assets/landing14.webp" alt="Treatment Icon" style="width:10vw;height: auto;">
      <p style="font-size: 28px; color:white;margin-top:60px">Canjea puntos<br> por descuentos</p>
    </div>
  </div>
</div>
</div>
</section>
<section v-else class="section section-3" id="section-7">
<div class="content" style="justify-content: center">
  <div>
    <div style="margin-bottom:10px;">
      <strong style="font-size:28px" class="text-white">Ahorra con dermapp</strong>
  </div>
  <div style="margin-bottom:30px;">
      <p style="font-size:24px" class="text-white">Acumula puntos<br> y obtén descuentos</p>
  </div>
  <div style="margin-bottom:30px;">
        <img src="@/assets/landing12.webp" alt="Problem Icon" style="width:30vw;height: auto; margin-bottom: 20px;">
    <p style="font-size: 18px;color:white">Cashback para<br> suscriptores</p>
  </div>
  <div class="d-flex" style="justify-content: space-between;">
    <div style="margin-right: 20px;">
    <img src="@/assets/landing13-2.webp" alt="Photos Icon" style="width:30vw;height: auto; margin-bottom: 20px;">
    <p style="font-size: 18px; color:white">Completa las<br> misiones</p>

    </div>
    <div>
      <img src="@/assets/landing14.webp" alt="Treatment Icon" style="width:30vw;height: auto; margin-bottom: 30px; margin-top: 10px;">
      <p style="font-size: 18px; color:white">Canjea puntos<br> por descuentos</p>
    </div>   
  </div>
  </div> 
</div>
</section>


<section v-if="!isMobile()" class="section section-8" id="section-8">
<div class="content">
  <div class="column left-column">
      <strong style="font-size:28px; color: white; margin-bottom:30px">Av Ignacio Morones Prieto 4500, Jesus M Garza 66238, San Pedro Garza Garcia, N.L.</strong>      
    <strong style="font-size:28px; color: white; margin-bottom:30px; margin-top:10px">+52 81 4005 2978</strong>
    <strong style="font-size:28px; color: white; margin-bottom:30px; margin-top:10px">soporte@dermapp.com</strong>
    <strong style="font-size:28px; color: white; margin-bottom:30px; margin-top:10px">Responsable sanitario Dra. Erika Pérez García. Cédula 9025417. Universidad Anáhuac.</strong>
    <strong style="font-size:28px; color: white; margin-bottom:30px; margin-top:10px">Permiso de publicidad 223300201A2587</strong>
    
    <a style="font-size:18px; margin-bottom:20px" data-v-251d9208="" href="/aviso-de-privacidad" class="mr-4"> Aviso de privacidad </a>
    <a style="font-size:18px" data-v-251d9208="" href="/terminos-y-condiciones" class="mr-4"> Terminos y condiciones </a>

    <div class="d-flex" style="margin-left:-200px">
      <div>
      </div>
    </div>
    
  </div>
  <div class="column right-column">
    <div>
      <img src="@/assets/dermapp-white.webp" alt="mobile-Logo" class="mobile-logo-column" style="width: 30vw; height: auto; margin-bottom: -30px; margin-top:20px"/>
      <p style="font-size:28px; color:white; margin-top:-50px">Consulta con dermatólogos certificados</p>

    </div>
  </div>
</div>
</section>
<section v-else class="section section-8" id="section-8">
<div class="content">
  <div class="column single-column">
  <div style="margin-bottom:-60px">
    <img src="@/assets/dermapp-white.webp" alt="mobile-Logo" style="width:90vw; height:auto;"/>
  </div>
  <div style="margin-bottom:30px;">
      <p style="font-size:18px" class="text-white">Consulta con dermatólogos certificados</p>
  </div>
  <div style="margin-left: 20px; margin-right: 20px;">
      <p style="font-size:18px" class="text-white">Av Ignacio Morones Prieto 4500, Jesus M Garza 66238, San Pedro Garza Garcia, N.L.</p>
  </div>
  <div style="margin-left: 20px; margin-right: 20px;">
      <p style="font-size:18px" class="text-white">+52 81 4005 2978</p>
  </div>
  <div style="margin-left: 20px; margin-right: 20px;">
      <p style="font-size:18px" class="text-white">soporte@dermapp.com</p>
  </div>
  <div style="margin-left: 20px; margin-right: 20px;">
      <p style="font-size:18px" class="text-white">Responsable sanitario Dra. Erika Pérez García. Cédula 9025417. Universidad Anáhuac.</p>
  </div>
  <div style="margin-left: 20px; margin-right: 20px;">
      <p style="font-size:18px" class="text-white">Permiso de publicidad 223300201A2587</p>
  </div>
  <div style="margin-bottom:20px">
    <a style="font-size:18px" data-v-251d9208="" href="/aviso-de-privacidad" class="mr-4"> Aviso de privacidad </a>
  </div>
  <div style="margin-bottom:20px">
    <a style="font-size:18px" data-v-251d9208="" href="/terminos-y-condiciones" class="mr-4"> Terminos y condiciones </a>
  </div>
</div>
</div>
</section>

    <footer class="footer">
      <div style="padding-top:20px">
        © 2023 Dermapp
      </div>
    </footer>
  </div>
</template>

<script>
import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';

export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('ViewContent', {content_name: 'Landing page' });
  
    });

    return {};
  },
  name: 'LandingPage',
  components: {
    // Add your components here
  },
  data() {
    return {
      // Your data here
    };
  },
  methods: {

    goToGuestAssessment() {
      this.$router.push('/consulta');
    },

      scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: 'smooth' });
      },

      goToSignIn() {

    this.$router.push('/signin');
  },

      isMobile() {
  return window.innerWidth <= 768; // Adjust this value based on your needs
},
  },
}
</script>

<style scoped>
.header {
  margin-top: -10px;
  position: fixed;
  width: 100%;
  height: 60px;
  background: #36b5ff;
  z-index: 100;
}

.logo {
height: 30px;
margin-left: 20px;
margin-top: 15px;
margin-bottom:15px;
}

.nav-item {
margin-right: 20px;
background: none;
border: none;
color: white;
cursor: pointer;
}

.signin-btn {
background: white;
color: #36b5ff;
border: none;
height: 30px;
padding-right:10px;
padding-left: 10px;
border-radius: 4px;
cursor: pointer;
margin-right: 20px;
}

.section {
  height: 100vh;
  width: 100%;
}

.content {
display: flex;
justify-content: space-between;
align-items: center;
height: 100%;
}

.column {
flex: 1;
}

.left-column {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-left: 50px;
}

.right-column {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.intro-text, .sub-text {
margin-bottom: 20px;
font-size:28px;
color: white;
}

.logo-text {
margin-bottom: 20px;
font-size:23px;
color: white;
}

.cta-button {
background-color: #5ce1e6;
font-size: 36px;
color: white;
border: none;
margin-bottom: 20px;
padding: 10px 50px;
border-radius: 50px;
cursor: pointer;
}

.cta-button-mobile {
background-color: #5ce1e6;
font-size: 20px;
color: white;
border: none;
margin-bottom: 20px;
padding: 10px 50px;
border-radius: 50px;
cursor: pointer;
}

.logo-column {
height: 85px;
margin-bottom: 10px;
}

.section-1 {
  background-color: #36b5ff;
}

.section-2 {
background-color: white;
display: flex;
}

.text-container {
flex: 1;
padding: 50px;
display: flex;
flex-direction: column;
justify-content: center;
text-align: left;
font-size: 28px;
}

.section-3 {
  background-color: #5ce1e6;
  padding-left:50px;
  padding-right:50px;
}

.section-8 {
  background-color: black;
}    

.s3-column {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

}

.s3-column img {
width: 150px; /* Adjust the size as needed */
height: 150px; /* Adjust the size as needed */
margin-bottom: 20px;
}

.s3-column p {
color: white;
font-size: 28px; /* Adjust the size as needed */
}


.section-4 {
background-color: white;
display: flex; 
flex-direction: row-reverse; 
}

.text-column {
flex: 1;
padding: 50px;
}

.text-column p {
font-size: 28px; /* Adjust as per your needs */
text-align: left;
color: #000; /* Adjust as per your needs */
}

.text-column ul {
list-style: none;
padding: 0;
font-size: 23px; /* Adjust as per your needs */
text-align: left;
color: #000;
}

.text-column ul li::before {
content: "• ";
color: #000; /* Adjust as per your needs */
}

.section-5 {
  background-color: #36b5ff;
  padding-left:50px;
  padding-right:50px;
}

.s5-column {
display: flex;
flex-direction: column;
justify-content: space-between;
width: 60vw;
}

.top-row, .bottom-row {
display: flex;
justify-content: space-around;
padding-left: 30px;
}

.top-row img {
width: 100%;
height: auto;
margin-bottom: 20px;
padding-left: 20px;
}

.bottom-row img {
width: 50%;
height: auto;
padding-left:20px;
}


.s5-text-column {
flex: 1;
padding: 50px;
font-size: 28px;
color: white;
text-align: left;
max-width: 40vw;
}


.section-6 {
background-color: #ff66c4;
display: flex;
}

.s6-column {
display: flex;
flex-direction: column;
justify-content: space-between;
width: 60vw;
}

.s6-column .top-row, .s6-column .bottom-row {
display: flex;
justify-content: space-around;
padding-right: 30px;
}

.s6-column .top-row img {
width: 100%;
height: auto;
margin-bottom: 20px;
padding-right: 20px;
}

.s6-column .bottom-row img {
width: 50%;
height: auto;
padding-right:20px;
}

.s6-text-column {
flex: 1;
padding: 50px;
font-size: 28px;
color: white;
text-align: left;
max-width: 40vw;
}


.section-7 {
  background-color: #36b5ff;
  display: flex;
  justify-content: center;
}


.section-7 .single-column {
max-width: 800px;  /* Adjust as needed */
}


.footer {
  height: 60px;
  background-color: white;
  color: black;
}
</style>
