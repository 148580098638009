<template>
    <div>
      <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
        <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
          <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
        </div>
        <nav class="d-flex align-items-center justify-content-end" style="width: 100%; text-align:right">
          <button 
            class="nav-item" 
            style="padding-left:10px; padding-right:10px; height:40px;width:auto;margin-right:20px;border: 2px solid white;border-radius: 10px; background: none; color: white; cursor: pointer;" 
            @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
            Créditos: {{ this.credits }}
          </button> 
        </nav>
      </header>
      <div class="container d-flex align-items-center" style="min-height: 100vh; width: 100vw; flex-direction: column;">
        <div class="card-container" style="width: 400px; max-width: 100%; height: 500px;">
        <div class="card">
          <h5 class="text-center mb-2 mt-4">Editar Usuario</h5>
          <div class="card-body">
            <form @submit.prevent="editProfile">
            <div style="text-align: left;">
                <div class="mb-3">
                <label for="name">Correo</label>
                <input type="text" class="form-control" id="email" v-model="this.email" :readonly=true>
              </div>
              <div class="mb-3">
                Whatsapp
                  <div class="input-group">
                  <input style="width:20%" class="form-control"  v-model="this.countryCode" placeholder="Ej. +52">
                  <input style="width:80%" class="form-control" v-model="this.new_phone">
                </div>              
              </div>
              <div class="mb-3">
                <p class="card-text" style="text-align: center">
                  Codigo de referencia<br>
                  {{ this.referralCode }}
                </p>
              </div>
              
            </div>
              <div>
                <button type="button" class="btn btn-primary mt-2 mb-2" style="width: 80%;" @click="goToChangePassword">Cambiar contraseña</button>
                <button type="submit" class="btn btn-primary" style="width: 80%;">Actualizar usuario</button>
              </div>
              <!-- Rest of the fields similar to the 'CreateProfile' form fields -->
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
  import { mapState } from 'vuex';
  
  
  export default {
    computed: {
      ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode']),
    },

    data() {
      // Initial state
      return {
        // Add initial profile object to fill the form
        profile: {
          referral_code: '',
          name:'',
          weight:'',
          height:'',
          sex:'',
          birthday: null,
          diseases_history:[],
          allergies:[],
        },
        disease:'',
        allergy:'',
        HeaderLogo: require('@/assets/dermapp-white.png'),
        new_phone: '',
        countryCode: ''
      };
    },

    mounted(){
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });

      this.new_phone=this.phone;
  
      // Check if phone has 10 digits, prefix with '52'
              switch(this.country) {
                  case 'US': this.countryCode= '+1'; break;
                  case 'MX': this.countryCode= '+52' ; break;
                  case 'AR': this.countryCode= '+54' ; break;
                  case 'BO': this.countryCode= '+591' ; break;
                  case 'BR': this.countryCode= '+55' ; break;
                  case 'CL': this.countryCode= '+56' ; break;
                  case 'CO': this.countryCode= '+57' ; break;
                  case 'CR': this.countryCode= '+506' ; break;
                  case 'CU': this.countryCode= '+53' ; break;
                  case 'DO': this.countryCode= '+1' ; break;
                  case 'EC': this.countryCode= '+593' ; break;
                  case 'SV': this.countryCode= '+503' ; break;
                  case 'GT': this.countryCode= '+502' ; break;
                  case 'HN': this.countryCode= '+504' ; break;
                  case 'NI': this.countryCode= '+505' ; break;
                  case 'PA': this.countryCode= '+507' ; break;
                  case 'PY': this.countryCode= '+595' ; break;
                  case 'PE': this.countryCode= '+51' ; break;
                  case 'PR': this.countryCode= '+1' ; break;
                  case 'UY': this.countryCode= '+598' ; break;
                  case 'VE': this.countryCode= '+58' ; break;
                  default: return '';
              }
                },
    
    methods: {

      goToChangePassword() {
      this.$router.push("/change-password");
    },

          // More methods
          addDisease() {
        if (this.disease) {
          this.profile.diseases_history.push(this.disease);
          this.disease = '';
        }
      },
      removeDisease(index) {
        this.profile.diseases_history.splice(index, 1);
      },
      addAllergy() {
        if (this.allergy) {
          this.profile.allergies.push(this.allergy);
          this.allergy = '';
        }
      },
      removeAllergy(index) {
        this.profile.allergies.splice(index, 1);
      },
      async editProfile() {
        let new_phoneNumber = this.countryCode + this.new_phone;
        try {

          const updatedUser = {
            email: this.email,
            phone: new_phoneNumber,
            user_id: this.userId,
          }

          const responseProfile = await axios.put(`${this.$baseURL}/api/update-user`, updatedUser);

          if(responseProfile.status === 200) {
            this.$router.push({ name: 'PatientDashboard', params: { id: updatedUser.user_id } });
          }
        } catch (error) {
          console.error('Error updating profile:', error);
        }
      },
    },

  }
</script>

<style scoped>

body {
  background: #38b6ff;
}
.header {
    margin-top: -5px;
    position: relative;
    width: 100%;
    height: auto;
    background: #36b5ff;
    z-index: 1;
  }

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

  </style>

