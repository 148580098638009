<template>
    <div>
      <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
        <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
          <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
        </div>
        <nav class="d-flex align-items-center justify-content-end" style="width: 100%; text-align:right">
          <button 
            class="nav-item" 
            style="padding-left:10px; padding-right:10px; height:40px;width:auto;margin-right:20px;border: 2px solid white;border-radius: 10px; background: none; color: white; cursor: pointer;" 
            @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
            Créditos: {{ this.credits }}
          </button> 

        </nav>
      </header>
      <div class="container d-flex align-items-center" style="min-height: 100vh; width: 100vw; flex-direction: column;">
        <div class="card-container" style="width: 400px; max-width: 100%;">
          <div class="card mb-3">
            <h5 v-if="step === 1" class="text-center mb-2 mt-4">Modifica tu consulta o <br>agrega información adicional</h5>
            <h5 v-if="step === 2" class="text-center mb-2 mt-4">Agrega imágenes o <br> estudios adicionales</h5>          <div class="card-body">
            <form @submit.prevent="nextStep">
              <div style="text-align: left;">
                <div v-if="step === 1">
                  <div class="stepper">
                    <span class="step" :class="{ active: step === 1 }">1</span>
                    <span class="step" :class="{ active: step === 2 }">2</span>
                  </div>
                  <h5 class="text-center mb-4">1. Información Clínica</h5>
                  <div class="mb-3">
                  <label>¿Has notado cambios con el tratamiento?</label>
                  <div>
                    <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="progressPositive" value="Positive" v-model="followup.progress">
                    <label style="margin-left: 10px;" for="progressPositive">Mi condición ha mejorado</label><br>
                    <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="progressNeutral" value="Neutral" v-model="followup.progress">
                    <label style="margin-left: 10px;" for="progressNeutral">Mi condición no ha mejorado</label><br>
                    <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="progressNegative" value="Negative" v-model="followup.progress">
                    <label style="margin-left: 10px;" for="progressNegative">Mi condición ha empeorado</label><br>
                  </div>
                </div>
                    <div class="mb-3">
                      <label for="reason">Platícanos más al respecto</label>
                      <textarea style="height:100px" type="text" class="form-control" id="reason" v-model="followup.comments"></textarea>
                </div>
                <div class="mb-3">
                  <label for="symptom">¿Qué síntomas tienes?</label>
                  <div class="input-group">
                    <input type="text" class="form-control" id="symptom" v-model="symptom" placeholder="Ingresa un síntoma">
                    <button class="btn btn-primary" type="button" @click="addSymptom">Agregar</button>
                  </div>
                  <ul class="list-group mt-3">
                    <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(symptom, index)
                  in followup.symptoms" :key="index">
                    {{ symptom }}
                    <button type="button" class="btn-close" @click="removeSymptom(index)"></button>
                  </li>
                </ul>
              </div>
              <div class="mb-3" v-if="this.profileSex === 'Female'">
                <label>¿Estás embarazada, lactando o planeas embarazarte en menos de 6 meses?</label>
                  <select class="form-control" id="pregnancy" v-model="followup.pregnancy_status">
                    <option value="">Selecciona una opción</option>
                    <option value="Pregnancy">Embarazada</option>
                    <option value="Lactancy">Lactando</option>
                    <option value="Plans">Planes de embarazo</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label>¿Pudiste conseguir todo tu tratamiento?</label>
                  <div>
                    <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="complete" value="Yes" v-model="previous">
                    <label style="margin-left: 10px;" for="complete">Si</label>
                    <input style="margin-left: 80px;" type="radio" id="incomplete" value="No" v-model="previous">
                    <label style="margin-left: 10px;" for="incomplete">No</label>
                  </div>
                </div>
                <div class="mb-3" v-if="previous === 'No'">
                  <label for="treatment-history">¿Qué productos conseguiste?</label>
                  <div class="input-group">
                    <select class="form-control" id="treatment-history" v-model="previousTreatment">
                      <option disabled value="">Please select one</option>
                      <option v-for="treatment in productsNotInCurrentTreatment" :key="treatment.id" :value="treatment.name">
                        {{treatment.name}}
                      </option>
                    </select>
                    <button class="btn btn-primary" type="button" @click="addPreviousTreatment">Agregar</button>
                  </div>
                  <ul class="list-group mt-3">
                    <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(treatment, index) in followup.current_treatment" :key="index">
                      {{ treatment }}
                      <button type="button" class="btn-close" @click="removePreviousTreatment(index)"></button>
                    </li>
                  </ul>
                </div>
                <div class="mb-3">
                  <label>¿Tienes productos de tu tratamiento?</label>
                  <div>
                    <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="ownedYes" value="Yes" v-model="owned">
                    <label style="margin-left: 10px;" for="ownedYes">Si</label>
                    <input style="margin-left: 80px;" type="radio" id="ownedNo" value="No" v-model="owned">
                    <label style="margin-left: 10px;" for="ownedNo">No</label>
                  </div>
                </div>
                <div class="mb-3" v-if="owned === 'Yes'">
                  <label for="treatment-history">¿Con que productos cuentas aún?</label>
                  <div class="input-group">
                    <select class="form-control" id="treatment-history" v-model="ownedProduct">
                      <option disabled value="">Selecciona un producto</option>
                      <option v-for="(treatment,index) in productsNotInOwnedProducts" :key="index" :value="treatment">
                        {{treatment}}
                      </option>
                    </select>
                    <button class="btn btn-primary" type="button" @click="addOwnedProduct">Agregar</button>
                  </div>
                  <ul class="list-group mt-3">
                    <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(treatment, index) in followup.owned_products" :key="index">
                      {{ treatment }}
                      <button type="button" class="btn-close" @click="removeOwnedProduct(index)"></button>
                    </li>
                  </ul>
                </div>
                <div class="mb-3">
                  <label>¿Tienes un límite en tu presupuesto?</label>
                  <div>
                    <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="changesYes" value="Yes" v-model="limit">
                    <label style="margin-left: 10px;" for="changesYes">Si</label>
                    <input style="margin-left: 80px;" type="radio" id="changesNo" value="No" v-model="limit">
                    <label style="margin-left: 10px;" for="changesNo">No</label>
                  </div>
                </div>
                <div class="mb-3" v-if="limit === 'Yes'">
                  <label for="budget">¿Cuál es tu presupuesto?</label>
                  <select class="form-control" id="budget" v-model="followup.budget">
                    <option value="">Selecciona un rango</option>
                    <option value="0 - 1500">0 - 1,500 MXN</option>
                    <option value="1500 - 3000">1,500 - 3,000 MXN</option>
                    <option value="3000 or more">3,000 o más</option>
                  </select>
                  <p style="font-size: 10px;">
                   <br> *Se recomienda un presupuesto minimo de entre 1500 y 2500 MXN, pero haremos lo posible por ajustarlo a tu capacidad.
                  </p>
                </div>
                <div class="mb-3 d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary" style="width:80%">Continuar</button>
                </div>                
                </div>
                <div v-else-if="step === 2">
                  <div class="stepper">
                    <span class="step" :class="{ active: step === 2 }">1</span>
                    <span class="step" :class="{ active: step === 2 }">2</span>
                  </div>
                  <h5 class="text-center mb-4">2. Imágenes Clínica</h5>
                  <div class="form-group">
                    <p class="mb-3">Necesitamos al menos dos imágenes de la zona afectada:</p>
                    <p class="mb-3" style="font-size:14px">
                      1. Una imagen cercana (aprox 10 cm)<br>
                      2. Una imagen lejana (aprox 30 cm)
                    </p>
                    <p class="mb-3">Instrucciones y recomendaciones para toma de fotografías:</p>
                    <p class="mb-3" style="font-size:14px">
                      1. Incluye imágenes de todas las zonas afectadas<br>
                      2. Utiliza la cámara trasera de tu smartphone<br>
                      3. De ser necesario pide ayuda a un tercero<br>
                      5. No edites las imágenes<br>
                      4. Deben estar bien iluminadas y enfocadas<br>
                      6. Quita la ropa, los accesorios y el maquillaje de las zonas afectadas<br>
                    </p>
                    <div v-if="this.imageFiles.length > 0" class="text-center">
                      <label for="images" class="btn btn-primary" style="width:80%">Agregar más imágenes</label>
                      <input class="d-none" type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="handleFileChange('images/')">
                    </div>
                    <div v-else class="text-center">
                      <label for="images" class="btn btn-primary" style="width:80%">Seleccionar Imágenes</label>
                      <input class="d-none" type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="handleFileChange('images/')">
                    </div>
                    <div class="carousel">
                      <div v-for="(file, index) in imageFiles" :key="index">
                        <img :src="file.thumbnail" v-if="file.thumbnail" />
                        <div class="progress" v-if="file.progress !== undefined">
                          <div class="progress-bar" role="progressbar" :style="{ width: file.progress + '%' }" :aria-valuenow="file.progress" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                      <p class="mb-3">
                        Si tienes estudios recientes compartelos (opcional)
                    </p>
                    <div v-if="this.pdfFiles.length>0" class="text-center">
                      <label for="pdfs" class="btn btn-primary" style="width:80%">Agregar más Exámenes</label>
                      <input class="d-none" type="file" id="pdfs" name="pdfs" accept="application/pdf" multiple ref="pdfsRef" @change="handleFileChange('exams/')">
                  </div>
                  <div v-else class="text-center">
                    <label for="pdfs" class="btn btn-primary" style="width:80%">Seleccionar Exámenes</label>
                    <input class="d-none" type="file" id="pdfs" name="pdfs" accept="application/pdf" multiple ref="pdfsRef" @change="handleFileChange('exams/')">
                </div>
                          <ul class="mt-3">
                              <li v-for="(file,index) in pdfFiles" :key=index>{{ file.file.name }} - {{ file.status }}</li>
                          </ul>
                <div class="mb-3 d-flex justify-content-center" >
                    <button type="submit" class="btn btn-primary mt-4" style="width:80%">Editar Seguimiento</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid'; // this generates a unique identifier
import Swal from 'sweetalert2';
import heic2any from 'heic2any';





export default {
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex']),
    productsNotInCurrentTreatment() {
    return this.current_treatment.filter(treatment => !this.followup.current_treatment.includes(treatment.name));
  },
  productsNotInOwnedProducts() {
    return this.followup.current_treatment.filter(treatment => !this.followup.owned_products.includes(treatment));
  }
  },

  data() {
    return {
      isUploading:false,
      imageFiles: [],
      pdfFiles: [],
      imageFail: false,
      assessment_id: null,
      followupAssessmentId: null,
      assessment: {
        id: null,
        consultation_reason: '',
        symptoms: [],
        locations: [],
        problem_duration: '',
        problem_evolution: '',
        treatment_history: [],
        current_treatment: [],
        budget: '',
        pregnancy_status: '',
        status: null,
      },
      followup:{
        id: null,
        assessment_id: null,
        progress:'',
        symptoms:[],
        comments:'',
        current_treatment:[],
        owned_products:[],
        budget:'',
        pregnancy_status:'',
        is_diagnosed: 0,
      },
      diagnosis:{
        id: null,
        assessment_id: null,
        diagnosis:'',
        explanation:'',
        prognosis:'',
        followup:'',
        recommendations:[],
        exams:[],
        followup_date:{
          amount:'',
          suffix:'',
        },
      },
      followup_diagnosis:{
        id: null,
        followup_assessment_id: null,
        recommendations:[],
        exams:[],
        comments:'',
        followup_date:{
          amount:'',
          suffix:'',
        },
        deleted_products:[],
        created_at:'',
      },
      symptom: '',
      previous: '',
      owned:'',
      limit: '',
      step: 1,
      previousTreatment: '',
      ownedProduct: '',
      current_treatment:[],
      HeaderLogo: require('@/assets/dermapp-white.png')
    };
  },

  mounted() {

    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });

    const token = localStorage.getItem('Token');
  this.assessment_id = this.$route.params.id;
  
  axios.get(`${this.$baseURL}/api/assessment-data`, {
      params: {
        assessment_id: this.assessment_id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((response) => {
      this.assessment = response.data.data;

    axios.get(`${this.$baseURL}/api/get-latest-followup-assessment`, {
      params: {
        assessment_id: this.assessment_id,
        mode: 3,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((response) => {
      this.followup = response.data.data;
      if (this.followup.symptoms!=null) {
        this.followup.symptoms = JSON.parse(this.followup.symptoms);
      }

      if (this.followup.current_treatment!=null) {
        this.followup.current_treatment = JSON.parse(this.followup.current_treatment);
        this.previous = 'No'
      }

      if (this.followup.owned_products!=null) {
        this.followup.owned_products = JSON.parse(this.followup.owned_products);
        this.owned = 'Yes'
      }

      if (this.followup.budget!='') {
        this.limit = 'Yes'
      }

      if(this.assessment.status == 2) {
        axios.get(`${this.$baseURL}/api/diagnosis-data`, {
      params: {
        assessment_id: this.assessment_id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((response) => {
      this.diagnosis = response.data.data;

      axios.get(`${this.$baseURL}/api/get-treatments`, {
      params: {
        diagnosis_id: this.diagnosis.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((response) => {
      this.current_treatment = response.data.data;

      this.isDataLoaded = true;
    })
    .catch((error) => {
      console.error("Error fetching diagnosis data:", error);
    });
  })
    .catch((error) => {
      console.error("Error fetching treatment data:", error);
    });
      } else if(this.assessment.status == 3) {
        axios.get(`${this.$baseURL}/api/followup-diagnosis-data`, {
      params: {
        followup_assessment_id: this.followup.id,
      },      
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((response) => {
      this.diagnosis = response.data.data;
      axios.get(`${this.$baseURL}/api/get-followup-treatments`, {
      params: {
        followup_diagnosis_id: this.followup_diagnosis.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((response) => {
      this.current_treatment = response.data.data;
      this.isDataLoaded = true;
    })
    .catch((error) => {
      console.error("Error fetching followup diagnosis data:", error);
    });
  })
    .catch((error) => {
      console.error("Error fetching followup treatment data:", error);
    });
      }      
    })
    .catch((error) => {
      console.error("Error fetching followup data:", error);
    });
    })
    .catch((error) => {
      console.error("Error fetching assessment data:", error);
    });
    
  // you can now use this id to fetch the specific patient's data
},

  
  methods: {

    async handleFileChange(folder) {
  let selectedFiles = [...event.target.files];
  let new_total = this.imageFiles.length + selectedFiles.length;
  let new_total_pdf = this.pdfFiles.length + selectedFiles.length;
  if (selectedFiles.length > 0) {
    this.isUploading = true;
  }

  for (let i = 0; i < selectedFiles.length; i++) {
    let file = selectedFiles[i];
    if (file.type === 'image/heic') {
      file = await this.convertHeicToJpeg(file);
    }
    const thumbnail = await this.createThumbnail(file);

    let tempFile = {
      file: file,
      status: 'Subiendo...',
      thumbnail: thumbnail,
      progress: 0
    };

    if (folder == 'images/') {
      this.imageFiles.push(tempFile);
      await this.uploadToS3(this.imageFiles[this.imageFiles.length - 1].file, folder, this.imageFiles.length - 1, new_total);
    } else {
      this.pdfFiles.push(tempFile);
      await this.uploadToS3(this.pdfFiles[this.pdfFiles.length - 1].file, folder, this.pdfFiles.length - 1, new_total_pdf);
    }
  }
},

async convertHeicToJpeg(file) {
  const convertedBlob = await heic2any({
    blob: file,
    toType: 'image/jpeg',
  });
  return new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: 'image/jpeg' });
},

async createThumbnail(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement('img');
      img.src = e.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 100;
        canvas.height = 100;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL('image/jpeg'));
      };
    };
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(file);
  });
},

async uploadToS3(file, folder, position, total) {
  const fileExtension = file.name.split('.').pop();
  const newFileName = `${uuidv4()}.${fileExtension}`; // creates a new unique file name
  const fileName = `${folder}${newFileName}`;
  const destination = 'dermapp-documents';
  const availability = 'public-read';

  const formData = new FormData();
  formData.append('destination', destination);
  formData.append('fileName', fileName);
  formData.append('file', file);
  formData.append('availability', availability);

  try {
    await axios.post(`${this.$baseURL}/api/upload-to-s3`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.imageFiles[position].progress = percentCompleted;
      },
    });

    await this.uploadToDB(newFileName, fileExtension, folder, position, total);
  } catch (error) {
    this.imageFail = true;
    this.isUploading = false;
    if (folder == 'images/') {
      this.imageFiles[position].status = 'Cancelado';
    } else {
      this.pdfFiles[position].status = 'Cancelado';
    }
    console.error('Error uploading files to s3:', error);
  }
},

async uploadToDB(fileName, fileExtension, folder, position, total) {
        let fileType = folder === 'images/' ? 0 : 1;
        try {
          const files_data = {
          followup_assessment_id: this.followup.id,
          file_name: fileName,
          file_extension: fileExtension,
          file_type: fileType,
        }
            await axios.post(`${this.$baseURL}/api/assessment-followup-files`, files_data);

            if(folder == 'images/'){
              this.imageFiles[position].status = 'Completado';
            }
            else{
              this.pdfFiles[position].status = 'Completado';
            }
            if(position+1==total){
              this.isUploading =false;
            }


        } catch (error) {
            console.error('Error creating assessment:', error);
            throw error; // Propagate the error so we can handle it in the calling function
        }
    },

    addSymptom() {
      if (this.symptom) {
        this.followup.symptoms.push(this.symptom);
        this.symptom = '';
      }
    },
    removeSymptom(index) {
      this.followup.symptoms.splice(index, 1);
    },
    
    addPreviousTreatment() {
  if (this.previousTreatment) {
    this.followup.current_treatment.push(this.previousTreatment);
    this.previousTreatment = '';
  }
},
    removePreviousTreatment(index) {
      this.followup.current_treatment.splice(index, 1);

    },

    addOwnedProduct() {
      if (this.ownedProduct) {
        this.followup.owned_products.push(this.ownedProduct);
        this.ownedProduct = '';
      }
    },
    removeOwnedProduct(index) {
      this.followup.owned_products.splice(index, 1);

    },
    nextStep() {
        if (this.step < 2) {
          this.step++;
        } else {
          this.editFollowupAssessment();
        }
      },

      async editFollowupAssessment() {
        this.assessment.profile_id = this.profileId;
        try {

          const updatedAssessment = {
            followup_assessment_id: this.followup.id,
            assessment_id: this.followup.assessment_id,
            progress: this.followup.progress,
            symptoms: this.followup.symptoms,
            comments: this.followup.comments,
            current_treatment: this.followup.current_treatment,
            owned_products: this.followup.owned_products,
            budget: this.followup.budget,
            pregnancy_status: this.followup.pregnancy_status,
            is_diagnosed: this.followup.is_diagnosed

          }

            // Make a POST request to your API endpoint to create the assessment
            const response = await axios.put(`${this.$baseURL}/api/update-followup-assessment`, updatedAssessment);
            if (response.status === 200) {
              Swal.fire({
  title: '<h3>Tu consulta de seguimiento<br> ha sido actualizada</h3>',
  html: '<div style="text-align: left;">' + // Container div for left alignment
        '<small>Tu dermatólogo revisará la consulta pronto para actualizar tu plan de tratamiento.<br><br>' +
        '</small>' +
        '</div>',
  icon: 'success',
  confirmButtonText: 'Continuar',
  confirmButtonColor: '#0d6efd',
  showCloseButton: true,

});  

                if(this.profileId!=''){
                  this.$router.push({ name: 'PatientProfile', params: { id: this.profileId } });
                } else {
                  this.$router.push({ name: 'LandingPage' });
                }            
            } else {
                console.error('Error updating followup assessment:', response);
            }
        } catch (error) {
            console.error('Error updating followup assessment:', error);
        }
    },
  },

}
</script>

<style scoped>

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 10px;
  padding: 10px;
}

.carousel img {
  scroll-snap-align: start;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10%;
  border: 2px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.carousel .progress {
  width: 100px;
  height: 5px;
  margin-top: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
  overflow: hidden;
}

.carousel .progress-bar {
  height: 5px;
  background-color: #007bff;
  transition: width 0.4s;
}

.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10%;
  margin-right: 10px;
  border: 2px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}


.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  width: 20px;
  height: 20px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #E0E0E0; /* gray color for inactive steps */
  display: inline-block;
  font-size: 14px;           /* font size for the step number */
  color: #777;               /* font color for inactive steps */
  line-height: 20px;         /* centers the number vertically */
  text-align: center;        /* centers the number horizontally */
}

.step.active {
  background-color: #3498db; /* blue color for the active step */
  color: #fff;               /* font color for the active step */
}

body {
  background: #38b6ff;
}
.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}
.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #f8f9fa;
    border-radius: 4px;
    font-size: 14px;
  }
  .custom-pdf-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #f8f9fa;
    border-radius: 4px;
    font-size: 14px;
    color: #6c757d;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
