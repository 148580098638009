<template>
<div class="container d-flex flex-column align-items-center justify-content-center" style="height: 100vh; width:100vw; margin-top:-10px">
    <img :src="Logo" alt="CreateLogo" class="create-logo" style="height: 100px;"/>
    <div class="card-container" style="width:100%; height: 500px;">
    <div class="card">
      <div class="card-body d-flex flex-column justify-content-between">
        <div>
          <h5 class="text-center mb-2 mt-4">Crear Cuenta</h5>
        <form @submit.prevent="signUp">
          <div class="mb-3">
            <input type="email" class="form-control" id="email" v-model="email" placeholder="Correo electrónico">
          </div>
          <div class="mb-3">
      <div class="input-group">
        <input :type="showPassword ? 'text' : 'password'" class="form-control" id="password" v-model="password" placeholder="Contraseña">
        <button class="btn btn-outline-secondary" type="button" @click="showPassword = !showPassword">{{ showPassword ? 'Ocultar' : 'Mostrar' }}</button>
      </div>
    </div>
    <div class="mb-3">
      <div class="input-group">
        <input :type="showConfirmPassword ? 'text' : 'password'" class="form-control" id="confirmPassword" v-model="confirmPassword" placeholder="Confirmar contraseña" :class="passwordsMatch ? 'is-valid' : 'is-invalid'">
        <button class="btn btn-outline-secondary" type="button" @click="showConfirmPassword = !showConfirmPassword">{{ showConfirmPassword ? 'Ocultar' : 'Mostrar' }}</button>
      </div>
    </div>
          <button type="submit" class="btn btn-primary text-white" style="width: 80%;" @click="signUp">Crear cuenta</button>
        </form>
        </div>
        <div>
          <hr class="mb-4" />
        <div class="d-grid gap-2">
          <button class="btn btn-danger" style="width: 80%; margin-left:10%" @click="signInWithGoogle">Crear cuenta con Google</button>
          <!-- <button class="btn btn-primary mb-4" style="width: 80%; margin-left:10%" @click="signInWithFacebook">Crear cuenta con Facebook</button> -->
        </div>
        </div>
      </div>
      <div class="card-footer text-center">
        <p class="mb-0">¿Ya tienes una cuenta? <router-link to="/signin"><br>Iniciar sesión</router-link></p>
      </div>
    </div>
  </div>
  </div>
</template>

  
  <script>
  import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, sendEmailVerification } from 'firebase/auth';
  import { mapActions } from 'vuex';
  import axios from 'axios';

  import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';

  export default {
    setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('ViewContent', { content_name: 'Sign up' });
     
    });

    return {};
  }, 
    data() {
      return {
        email: "",
        password: "",
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
        errorMessage: null, // Add this line
        Logo: require('@/assets/dermapp-white.webp')
      };
    },
    computed: {
  passwordsMatch() {
    return this.password === this.confirmPassword;
  },
},
    methods: {
      ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),

      async signUp() {
  try {
    const auth = getAuth();
    const result = await createUserWithEmailAndPassword(auth, this.email, this.password);

    await sendEmailVerification(result.user);


    const user = result.user;

    // Obtén el token de ID
    const idToken = await user.getIdToken();

    localStorage.setItem('Token', idToken);
    localStorage.setItem('Email', this.email);
    this.updateEmail(this.email);



    this.$router.push({ name: 'CreateUser', params: { email: this.email } });
  } catch (error) {
    this.errorMessage = error.message;
  }
},
async signInWithProvider(provider) {
  try {
    const auth = getAuth();
    const result = await signInWithPopup(auth, provider);

      const userEmail = result.user.email;

      const user = result.user;

      // Obtén el token de ID
      const idToken = await user.getIdToken();

      localStorage.setItem('Token', idToken);

      this.updateEmail(userEmail);

    // Fetch user data
    const response = await axios.get(`${this.$baseURL}/api/user-data-v2`, {
      params: {
        email: userEmail,
        page: `Sign in ${provider.providerId}`
      },
      headers: {
          'Authorization': `Bearer ${idToken}`
        }
    });

    const success = response.data.success;
    if (success) {
      this.$router.push({ path: '/patient-dashboard', query: { email: userEmail } });
    } else {
      // Navigate to CreateUser if the user data fetch was not successful
      this.$router.push({ name: 'CreateUser', params: { email: userEmail } });
    }
  } catch (error) {
    console.error(`Error signing in with ${provider.providerId}:`, error);
    // Optionally navigate to an error page or show a notification
  }
},

async signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  await this.signInWithProvider(provider);
},

async signInWithFacebook() {
  const provider = new FacebookAuthProvider();
  await this.signInWithProvider(provider);
},


},
  };
  </script>

  <style>
  body {
  background: #38b6ff !important;
  }
  .is-valid {
  border-color: #28a745;
  background-color: rgba(40, 167, 69, 0.1);
}

.is-invalid {
  border-color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
}
  </style>