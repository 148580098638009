<template>
    <div>
      <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
        <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
          <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
        </div>
        <nav class="d-flex align-items-center justify-content-end" style="width: 100%; text-align:right">
          <button 
            class="nav-item" 
            style="padding-left:10px; padding-right:10px; height:40px;width:auto;margin-right:20px;border: 2px solid white;border-radius: 10px; background: none; color: white; cursor: pointer;" 
            @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
            Créditos: {{ this.credits }}
          </button> 
        </nav>
      </header>
      <div class="container">
        <div class="row justify-content-center" >
          <div class="col-md-8">
            <div class="card mb-2">
              <h5 class="text-center mb-2 mt-4">Método de Pago</h5>
              <div class="card-body mb-2">
                <p style="text-align:center">
                  Completa el pago y obtén tu plan <br> de tratamiento en menos de 24 horas<br><br>
                  ¿Cuál es el metodo de pago<br> de tu preferencia?
                </p>
                <div class="card mb-2">
              <div class="card-body">
                <h5 class=" mb-3">Tarjeta de débito o crédito</h5>
                <button class="btn btn-primary" style="width:80%" @click="cardPayment(`${this.$price}`)">Pagar con tarjeta</button>
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-body">
                <h5 class=" mb-3">Transferencia interbancaria</h5>
                <router-link :to="{ name: 'FollowupWireTransfer', params: { id: this.$route.params.id }}" class="btn btn-primary" style="width:80%">Pagar por transferencia</router-link>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class=" mb-3">Depósito en efectivo</h5>
                <router-link :to="{ name: 'FollowupCashPayments', params: { id: this.$route.params.id }}" class="btn btn-primary" style="width:80%">Pagar en efectivo</router-link>
              </div>
            </div>
              </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
    import { getAuth, onAuthStateChanged } from 'firebase/auth';
  import { mapState, mapActions } from 'vuex';
  import { loadStripe } from '@stripe/stripe-js';
  import axios from 'axios';

  import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';

  export default {
    setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('SubmitApplication', { content_name: 'Create Followup Assessment' });
      track('ViewContent', { content_name: 'Followup Payment Method' });
  
    });

    return {};
  },

    data() {
      return {
        assessment_id: null,
        HeaderLogo: require('@/assets/dermapp-white.png')
      };
    },
    computed: {
      ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName', 'primaryName', 'primaryBirthday']),
    },
    mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });

  this.assessment_id = this.$route.params.id;
  // Fetch the user data
  axios.get(`${this.$baseURL}/api/user-by-assessment`, {
      params: {
        assessment_id: this.assessment_id || this.$route.params.id,
      },
    })
    .then((response) => {
      const data = response.data.data;
      this.updateUserId(data.user.id);
      this.updateEmail(data.user.email);
      this.updatePhone(data.user.phone, data.user.country);
      this.updateCountry(data.user.country);
      this.updateCredits(data.user.credits);
      this.updateReferralCode(data.user.referral_code);
      this.updateProfileId(data.profile.id);
      this.updateProfileName(data.profile.name.split(' ')[0]);
      this.updateProfileSex(data.profile.sex);
      this.updateProfileSubscription(data.profile.subscription);   
    
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
},
methods: {

  ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),

async cardPayment(price) {

const stripe = await loadStripe(`${this.$loadstripepk}`);
axios
.post(`${this.$baseURL}/api/create-followup-checkout-session`, {
user_id: this.userId,
email: this.email,
price: price, // Change this property name to match the backend
assessment_id: this.assessment_id,
payment_method: 'card',
phone: this.phone,
patient_name: this.profileName,
}) 
.then((response) => {
const sessionId = response.data.sessionId;
stripe.redirectToCheckout({ sessionId });
})
.catch((error) => {
console.error('Error creating checkout session:', error);
});
},
},
  };
  </script>

<style scoped>

.header {
    margin-top: -5px;
    position: relative;
    width: 100%;
    height: auto;
    background: #36b5ff;
    z-index: 1;
  }

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

  </style>
  