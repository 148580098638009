export default function useFacebookPixel() {
    const fbq = window.fbq;
  
    async function track(eventName, params, deduplicate) {
      if (fbq) {
        if (deduplicate !== undefined) {
          // If deduplicate is provided, pass it to the fbq function
          fbq('track', eventName, params, deduplicate);
        } else {
          // If deduplicate is not provided, call fbq without it
          fbq('track', eventName, params);
        }
      } else {
        console.error('Facebook Pixel not initialized');
      }
    }
  
    return { track };
  }
  
