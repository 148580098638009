<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
      </div>
      <nav class="d-flex align-items-center justify-content-end" style="width: 100%; text-align:right">
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px;width:auto;margin-right:20px;border: 2px solid white;border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ this.credits }}
        </button> 
      </nav>
    </header>
    <div class="container">
      <div class="row justify-content-center" >
        <div class="col-md-8">
          <div class="card mb-2">
            <div class="card-body">
              <h5 class="text-center mb-2 mt-4">Transferencia Interbancaria</h5>
              <p class="card-text" style="text-align: left; margin-top: 20px;">
                1. Ingresa a tu aplicación bancaria o acude al banco más cercano. <br><br>
                2. Proporciona los siguientes datos de cuenta: <br><br>
                Banco: BBVA <br>
                Clabe: 012580001228631386 <br>
                Beneficiario: MONTERREY HEALTHTECH SOLUTIONS SAPI DE CV<br>
                Monto: $180 MXN <br>
                Concepto: CONSULTA DERMATOLOGICA <br><br>
                3. Envía el comprobante a la plataforma dando clic en el botón.<br>
               </p>
              <div class="text-center">
                <label for="images" class="btn btn-primary">Enviar comprobante</label>
                <input class="d-none" type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="updateReceiptFile">
            </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapState } from 'vuex';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // this generates a unique identifier

import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';
  


export default {
  setup() {
    const { track } = useFacebookPixel();


    onMounted(() => {
      track('ViewContent', {content_name: 'Followup Wire transfer' });
      track('InitiateCheckout', { content_name: 'Initiate followup wire transfer payment' });

    });

    return {};
  }, 
  data() {
  return {
      assessment_id: null,
      HeaderLogo: require('@/assets/dermapp-white.png'),
      receiptFiles: []  // New data property
  };
},
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName', 'primaryName', 'primaryBirthday']),
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });

this.assessment_id = this.$route.params.id;

},
  methods: {
      updateReceiptFile(event) {
      this.receiptFiles = [...event.target.files];
      if(this.receiptFiles.length>0){
        this.uploadReceipt();
      }
      
  },

  async uploadToS3(file, folder) {
  const fileExtension = file.name.split('.').pop();
  const newFileName = `${uuidv4()}.${fileExtension}`; // creates a new unique file name
  const fileName = `${folder}${newFileName}`;
  const destination = 'dermapp-documents';
  const availability = 'public-read';

  const formData = new FormData();
  formData.append('destination', destination);
  formData.append('fileName', fileName);
  formData.append('file', file);
  formData.append('availability', availability);

  try {
    await axios.post(`${this.$baseURL}/api/upload-to-s3`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    await this.uploadToDB(newFileName, fileExtension);
  } catch (error) {
    console.error('Error uploading files to s3:', error);
  }
},

async uploadToDB(fileName, fileExtension){
let fileType = 2;
    
    try {
      const files_data = {
        assessment_id: this.$route.params.id,
        file_name: fileName,
        file_extension: fileExtension,
        file_type: fileType,
      }


await axios.post(`${this.$baseURL}/api/assessment-files`, files_data);

const payment_data = {
        assessment_id: this.$route.params.id,
        status: 1,
        payment_method: 'wire-transfer',
        phone: this.phone,
        email: this.email,
        patient_name: this.profileName
      }

await axios.put(`${this.$baseURL}/api/update-assessment-payment`, payment_data);


              this.$router.push({ name: 'FollowupPaymentSuccess', params: { email: this.email} });      


} catch (error) {
console.error('Error creating assessment:', error);
}
},

async uploadReceipt() {

      try {
          // Make a POST request to your API endpoint to create the assessment

              // Now that we have an assessmentId, we can upload the files
              const images = this.$refs.imagesRef.files;
              for(let i=0; i<images.length; i++) {
                  await this.uploadToS3(images[i], 'receipts/');
              }

              
          
          }
      catch (error) {
          console.error('Error uploading receipt:', error);
      }
  },
  }
};
</script>

<style scoped>

.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}

.nav-item {
margin-right: 20px;
background: none;
border: none;
color: white;
cursor: pointer;
}

</style>