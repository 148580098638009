<template>
<div class="container d-flex flex-column align-items-center justify-content-center" style="height: 100vh; width:100vw; margin-top:-10px">
    <img :src="Logo" alt="CreateLogo" class="create-logo" style="height: 100px;"/>
    <div class="card-container" style="width: 100%; height: 500px;">
    <div class="card">
      <div class="card-body d-flex flex-column justify-content-between"> <!-- Add d-flex, flex-column, and justify-content-between here -->
        <div>
          <h5 class="text-center mb-2 mt-4">Iniciar Sesión</h5>
          <form @submit.prevent="signIn">
            <div class="mb-3">
              <input type="email" class="form-control" id="email" v-model="email" placeholder="Correo electrónico">
            </div>
            <div class="mb-3">
              <input type="password" class="form-control" id="password" v-model="password" placeholder="Contraseña">
            </div>
            <button type="submit" class="btn btn-primary text-white" style="width: 80%;">Iniciar sesión</button>
          </form>
        </div>
        <div>
          <hr class="mb-4"/>
          <div class="d-grid gap-2">
            <button class="btn btn-danger" style="width: 80%; margin-left:10%" @click="signInWithGoogle">Iniciar sesión con Google</button>
<!--             <button class="btn btn-primary mb-4" style="width: 80%; margin-left:10%" @click="signInWithFacebook">Iniciar sesión con Facebook</button>
 -->          </div>
        </div>
      </div>
      <div class="card-footer text-center">
        <p class="mb-0">¿Aún no tienes cuenta? <router-link to="/signup"><br>Crear cuenta</router-link></p>
        <p class="mb-0">¿Olvidaste tu contraseña? <a href="#" @click="promptForPasswordReset"><br>Recuperar contraseña</a></p>

      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, sendPasswordResetEmail  } from 'firebase/auth';
import { mapActions } from 'vuex';
import axios from 'axios';
import Swal from 'sweetalert2';


import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';

export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('ViewContent', { content_name: 'Sign in' });
      
    });

    return {};
  },
  data() {
    return {
      email: "",
      password: "",
      Logo: require('@/assets/dermapp-white.webp')

    };
  },
  methods: {

    async promptForPasswordReset() {
  const { value: email } = await Swal.fire({
    title: '<h3>¿Olvidaste tu contraseña?</h3>',
    html:
      '<div style="text-align: left;">' +
      '<small>Ingresa tu correo y da clic en el botón para recuperar tu contraseña.<br>' +
      '<input id="swal-input1" class="swal2-input" placeholder="Tu correo electrónico"><br><br>' +
      'Te enviaremos un correo para que puedas reestablecerla.<br>' +
      '</small>' +
      '</div>',
      denyButtonText: 'Regresar',
                    denyButtonColor: '#0d6efd',
                    confirmButtonText: 'Continuar',
                    confirmButtonColor: '#0d6efd',
                    showCloseButton: true,
                    showCancelButton: false,  // Ensuring no extra cancel button
                    showDenyButton: true,
                    focusConfirm: false,
                    didOpen: () => {
  const confirmButton = document.querySelector('.swal2-confirm');
  const denyButton = document.querySelector('.swal2-deny');
  confirmButton.parentNode.insertBefore(denyButton, confirmButton);
},
    preConfirm: () => {
      return document.getElementById('swal-input1').value;
    }
  });

  if (email) {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      Swal.fire({
        title: '¡Correo de recuperación enviado!',
        text: 'Revisa tu correo electrónico y sigue las instrucciones para recuperar tu cuenta.',
        icon: 'success',
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#0d6efd'
      });
    } catch (error) {
      console.error('Error sending password reset email:', error);
      Swal.fire({
        title: '<h3>Parece que ocurrió un error</h3>',
        html:
          '<div style="text-align: left;">' +
          '<small>Por favor, contáctanos por WhatsApp al +528140052978 o a través de nuestras redes sociales para ayudarte a recuperar tu cuenta.' +
          '</small>' +
          '</div>',
        icon: 'error',
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#0d6efd',
        showCloseButton: true,

      });
    }
  }
},


...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),

async signIn() {
  try {
    // Sign in the user
    const result = await signInWithEmailAndPassword(getAuth(), this.email, this.password);
    const user = result.user;

    // Obtén el token de ID
    const idToken = await user.getIdToken();

    localStorage.setItem('Token', idToken);
    localStorage.setItem('Email', this.email);
    // Optionally update the email in the store
    this.updateEmail(this.email);

    // Navigate to the patient dashboard with the email as a query parameter
    this.$router.push({ path: '/patient-dashboard', query: { email: this.email } });
  } catch (error) {
    console.error('Error signing in:', error);
  }
},

async signInWithProvider(provider) {
  try {
    const auth = getAuth();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    // Obtén el token de ID
    const idToken = await user.getIdToken();

    localStorage.setItem('Email', result.user.email);
    localStorage.setItem('Token', idToken);
    const userEmail = result.user.email;
    this.updateEmail(userEmail);

    // Fetch user data
    const response = await axios.get(`${this.$baseURL}/api/user-data-v2`, {
      params: {
        email: userEmail,
        page: `Sign in ${provider.providerId}`
      },
      headers: {
          'Authorization': `Bearer ${idToken}`
        }
    });

    const success = response.data.success;
    if (success) {
      this.$router.push({ path: '/patient-dashboard', query: { email: userEmail } });
    } else {
      // Navigate to CreateUser if the user data fetch was not successful
      this.$router.push({ name: 'CreateUser', params: { email: userEmail } });
    }
  } catch (error) {
    console.error(`Error signing in with ${provider.providerId}:`, error);
    // Optionally navigate to an error page or show a notification
  }
},

async signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  await this.signInWithProvider(provider);
},

async signInWithFacebook() {
  const provider = new FacebookAuthProvider();
  await this.signInWithProvider(provider);
},

  },
};
</script>

<style>
body {
  background: #38b6ff;
}

.swal2-confirm {
  float: right !important; /* Ensures the confirm button is on the right */
}
.swal2-deny {
  float: left !important; /* Ensures the deny button is on the left */
}

</style>