<template>
    <div>
      <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
        <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
          <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
        </div>
      </header>
      <div class="container d-flex align-items-center" style="min-height: 100vh; width: 100vw; flex-direction: column;">
        <div class="card-container" style="width: 100%;">
        <div class="card mb-3">
          <h5 v-if="step === 0" class="text-center mb-2 mt-4">Consulta con dermatólogos<br>por sólo 180 MXN</h5>
                  <h5 v-if="step === 1" class="text-center mb-2 mt-4">Obtén un tratamiento en<br>menos de 24 horas</h5>
                  <h5 v-if="step === 2" class="text-center mb-2 mt-4">Ahorra hasta 50% con fórmulas<br>magistrales en tu tratamiento</h5>
          <div class="card-body">
            <div v-if="isUploading" class="loading-indicator">
              <p>Subiendo archivos, por favor espera...</p>
            </div>
            
            <form @submit.prevent="nextStep()">
              <div style="text-align: left;">
                <div v-if="step === 0">
                    <div class="stepper">
                    <span class="step" :class="{ active: step === 0 }">1</span>
                    <span class="step" :class="{ active: step === 2 }">2</span>
                    <span class="step" :class="{ active: step === 2 }">3</span>
                  </div>
                  <h5 class="text-center mb-4">1. Información Personal</h5>
                  <div class="mb-3">
                    Nombre
                <input type="text" class="form-control" id="name" v-model="name" placeholder="Ingresa tu nombre" required>
              </div>
              <div class="mb-3">
                Apellido
            <input type="text" class="form-control" id="name" v-model="last_name" placeholder="Ingresa tu apellido" required>
          </div>
                  <div class="mb-3">
                    Email
              <input type="email" class="form-control" id="email" v-model="email" placeholder="Ingresa tu correo" required>
            </div>
            <div class="mb-3">
              Whatsapp
              <vue-tel-input ref="phoneInput" v-model="phone" mode="international" @input="handleUpdate" required></vue-tel-input>
              <p v-if="!this.isPhoneValid && this.phone.length>0" style="color:red">Porfavor ingrese un numero de telefono valido</p>
            </div>            
            <div class="mb-3">
              Fecha de nacimiento
              <div class="input-group">
                <input type="date" class="form-control" v-model="birthday" required>
              </div>
            </div>
              <div class="mb-3">
              Peso
                <input type="text" class="form-control" id="weight" v-model="weight" placeholder="Ingresa tu peso (kilogramos)" required>
              </div>
              <div class="mb-3">
              Altura
                <input type="text" class="form-control" id="height" v-model="height" placeholder="Ingresa tu altura (metros)" required>
              </div>
              <div class="mb-3">
              Sexo
                <select class="form-control" id="sex" v-model="sex" required>
                  <option value="" disabled selected>Selecciona tu sexo</option>
                  <option value="Male">Masculino</option>
                  <option value="Female">Femenino</option>
                </select>
              </div>
              <div>
                ¿Tienes alguna enfermedad?    
              </div>
              <div class="mb-3">
                <div class="input-group">
                  <input type="text" class="form-control" id="disease" v-model="disease" placeholder="Ingresa tu padecimiento">
                  <button class="btn btn-primary" type="button" @click="addDisease">Agregar</button>
                </div>
                <ul class="list-group mt-3">
                  <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(disease, index) in diseasesHistory" :key="index">
                    {{ disease }}
                    <button type="button" class="btn-close" @click="removeDisease(index)"></button>
                  </li>
                </ul>
              </div>
              <div>
                ¿Tienes alergia a algún medicamento?
          </div>
              <div class="mb-3">
                <div class="input-group">
                  <input type="text" class="form-control" id="allergies" v-model="allergy" placeholder="Ingresa tu alergia">
                  <button class="btn btn-primary" type="button" @click="addAllergy">Agregar</button>
                </div>
                <ul class="list-group mt-3">
                  <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(allergy, index) in allergies" :key="index">
                    {{ allergy }}
                    <button type="button" class="btn-close" @click="removeAllergy(index)"></button>
                  </li>
                </ul>
              </div>
              <div class="mb-4 d-flex justify-content-center">
                      <button type="submit" class="btn btn-primary mt-4">Continuar</button>
                  </div>                
                </div>
                <div v-if="step === 1">
                  <div class="stepper">
                    <span class="step" :class="{ active: step === 1 }">1</span>
                    <span class="step" :class="{ active: step === 1 }">2</span>
                    <span class="step" :class="{ active: step === 2 }">3</span>
                  </div>
                  <h5 class="text-center mb-4">2. Información Clínica</h5>
                    <div class="mb-3">
                  <label for="reason">¿Cuál es el motivo de la consulta?</label>
                  <textarea required type="text" class="form-control" id="reason" v-model="this.assessment.consultation_reason" placeholder="Ingresa sólo un problema por consulta"></textarea>
                </div>
                <div class="mb-3">
                <label for="location">¿Cuáles son las zonas afectadas?</label>
                  <div class="input-group">
                    <input  type="text" class="form-control" id="location" v-model="location" placeholder="Ingresa una zona">
                    <button class="btn btn-primary" type="button" @click="addLocation">Agregar</button>
                  </div>
                  <ul class="list-group mt-3">
                    <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(location, index)
                  in this.assessment.locations" :key="index">
                    {{ location }}
                    <button type="button" class="btn-close" @click="removeLocation(index)"></button>
                  </li>
                </ul>
              </div>
                <div class="mb-3">
                  <label for="symptom">¿Qué síntomas tienes?</label>
                  <div class="input-group">
                    <input type="text" class="form-control" id="symptom" v-model="symptom" placeholder="Ingresa un síntoma">
                    <button class="btn btn-primary" type="button" @click="addSymptom">Agregar</button>
                  </div>
                  <ul class="list-group mt-3">
                    <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(symptom, index)
                  in assessment.symptoms" :key="index">
                    {{ symptom }}
                    <button type="button" class="btn-close" @click="removeSymptom(index)"></button>
                  </li>
                </ul>
              </div>
              <div class="mb-3">
                  <label for="duration">¿Cuánto tiempo tienes con el problema?</label>
                  <select class="form-control" id="duration" v-model="this.assessment.problem_duration">
                    <option value="">Selecciona la duración aproximada</option>
                    <option value="Days">Días</option>
                    <option value="Weeks">Semanas</option>
                    <option value="Months">Meses</option>
                    <option value="Years">Años</option>
                  </select>
                </div>
                <div class="mb-3" v-if="this.sex === 'Female'">
                  <label>¿Estás embarazada, lactando o planeas embarazarte en menos de 6 meses?</label>
                  <select class="form-control" id="pregnancy" v-model="this.assessment.pregnancy_status" :required="this.sex === 'Female'">
                      <option value="">Selecciona una opción</option>
                      <option value="Pregnancy">Embarazada</option>
                      <option value="Lactancy">Lactando</option>
                      <option value="Plans">Planes de embarazo</option>
                      <option value="No">No</option>
                  </select>
              </div>              
                <div class="mb-3">
                  <label>¿Has notado cambios con el tiempo?</label>
                  <div>
                    <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="changesYes" value="Yes" v-model="changes">
                    <label style="margin-left: 10px;" for="changesYes">Si</label>
                    <input style="margin-left: 80px;" type="radio" id="changesNo" value="No" v-model="changes">
                    <label style="margin-left: 10px;" for="changesNo">No</label>
                  </div>
                </div>
                <div class="mb-3" v-if="changes === 'Yes'">
                  <label for="evolution">¿Qué cambios has notado?</label>
                  <textarea class="form-control" id="evolution" v-model="this.assessment.problem_evolution"></textarea>
                </div>
                <div class="mb-3">
                  <label>¿Has probado algún tratamiento?</label>
                  <div>
                    <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="changesYes" value="Yes" v-model="previous">
                    <label style="margin-left: 10px;" for="changesYes">Si</label>
                    <input style="margin-left: 80px;" type="radio" id="changesNo" value="No" v-model="previous">
                    <label style="margin-left: 10px;" for="changesNo">No</label>
                  </div>
                </div>
                <div class="mb-3" v-if="previous === 'Yes'">
                <label for="treatment-history">¿Qué tratamientos has utilizado?</label>
                
                <div class="input-group">
                  <input type="text" class="form-control" id="treatment-history" v-model="previousTreatment" placeholder="Ingresa un tratamiento">
                  <button class="btn btn-primary" type="button" @click="addPreviousTreatment">Agregar</button>
                </div>
                <ul class="list-group mt-3">
                  <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(treatment, index) in this.assessment.treatment_history" :key="index">
                    {{ treatment }}
                    <button type="button" class="btn-close" @click="removePreviousTreatment(index)"></button>
                  </li>
                </ul>
              </div>
                <div class="mb-3">
                  <label>¿Utilizas productos actualmente?</label>
                  <div>
                    <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="changesYes" value="Yes" v-model="current">
                    <label style="margin-left: 10px;" for="changesYes">Si</label>
                    <input style="margin-left: 80px;" type="radio" id="changesNo" value="No" v-model="current">
                    <label style="margin-left: 10px;" for="changesNo">No</label>
                  </div>
                </div>
                <div class="mb-3" v-if="current === 'Yes'">
                <label for="currentTreatment">¿Qué productos utilizas actualmente?</label>

                <div class="mb-3">
              <div class="input-group">
                <input type="text" class="form-control" v-model="searchQuery" @input="performSearch" placeholder="Ingresa un producto" >
                <button class="btn btn-primary" type="button" @click="addTreatment(addProduct)">Agregar</button>
               </div>
              <div class="dropdown" v-if="searchResults.length > 0 & searchQuery!=''">
              <div class="dropdown-item" v-for="(drug, index) in searchResults" :key="index" @click="selectProduct(drug)">
                {{ drug.name }}
              </div>
              </div>
                    <ul class="list-group mt-3" v-if="treatment_item!=''">
                      <div class="mb-3 ">
                        <li v-for="(treatment,index) in this.assessment.current_treatment" :key="index" class="list-group-item d-flex justify-content-between align-items-center rounded">
                              {{ treatment.name }}
                              <button type="button" class="btn-close" @click="removeCurrentTreatment(index)"></button>
                        </li>
                      </div>
                    </ul>
                  </div>
              </div>
                <div class="mb-3">
                  <label>¿Tienes un límite en tu presupuesto?</label>
                  <div>
                    <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="changesYes" value="Yes" v-model="limit">
                    <label style="margin-left: 10px;" for="changesYes">Si</label>
                    <input style="margin-left: 80px;" type="radio" id="changesNo" value="No" v-model="limit">
                    <label style="margin-left: 10px;" for="changesNo">No</label>
                  </div>
                </div>
                <div class="mb-3" v-if="limit === 'Yes'">
                  <label for="budget">¿Cuál es tu presupuesto?</label>
                  <select class="form-control" id="budget" v-model="this.assessment.budget">
                    <option value="">Selecciona un rango</option>
                    <option value="0 - 1500">0 - 1,500 MXN</option>
                    <option value="1500 - 3000">1,500 - 3,000 MXN</option>
                    <option value="3000 or more">3,000 o más</option>
                  </select>
                  <p style="font-size: 10px;">
                    <br> Se recomienda un presupuesto minimo de entre 1250 y 2000 MXN, pero haremos lo posible por ajustarlo a tus necesidades.
                  </p>
                </div>
                <div class="mb-3 d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary">Continuar</button>
                </div>                
                </div>
                <div v-else-if="step === 2">
                  <h5 class="text-center mb-4">3. Imágenes Clínicas</h5>
                  <div class="stepper">
                    <span class="step" :class="{ active: step === 2 }">1</span>
                    <span class="step" :class="{ active: step === 2 }">2</span>
                    <span class="step" :class="{ active: step === 2 }">3</span>
                  </div>
                  <div class="form-group">
                    <p class="mb-3">Necesitamos al menos dos imágenes de la zona afectada:</p>
                    <p class="mb-3" style="font-size:14px">
                      1. Una imagen cercana (aprox 10 cm)<br>
                      2. Una imagen lejana (aprox 30 cm)
                    </p>
                    <p class="mb-3">Instrucciones y recomendaciones para toma de fotografías:</p>
                    <p class="mb-3" style="font-size:14px">
                      1. Incluye imágenes de todas las zonas afectadas<br>
                      2. Utiliza la cámara trasera de tu smartphone<br>
                      3. De ser necesario pide ayuda a un tercero<br>
                      5. No edites las imágenes<br>
                      4. Deben estar bien iluminadas y enfocadas<br>
                      6. Quita la ropa, los accesorios y el maquillaje de las zonas afectadas<br>
                    </p>
                    <div v-if="this.imageFiles.length > 0" class="text-center">
                      <label for="images" class="btn btn-primary" style="width:80%">Agregar más imágenes</label>
                      <input class="d-none" type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="handleFileChange('images/')">
                    </div>
                    <div v-else class="text-center">
                      <label for="images" class="btn btn-primary" style="width:80%">Seleccionar Imágenes</label>
                      <input class="d-none" type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="handleFileChange('images/')">
                    </div>
                    <div class="carousel">
                      <div v-for="(file, index) in imageFiles" :key="index">
                        <img :src="file.thumbnail" v-if="file.thumbnail" />
                        <div class="progress" v-if="file.progress !== undefined">
                          <div class="progress-bar" role="progressbar" :style="{ width: file.progress + '%' }" :aria-valuenow="file.progress" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  
                  
                    <!-- <div v-if="imageFail" class="text-center">
                      <p class="mb-3" style="color:red">
                            PARECE QUE OCURRIO UN ERROR AL ENVIAR LAS IMAGENES<br><br>
                            Da clic en el boton para enviarlas por whatsapp
                    </p>
                    <button class="btn btn-primary mb-4" type="button" @click="openWhatsapp()">Enviar por Whatsapp</button>
                    </div> -->
                    <p class="mb-3">
                      Si tienes estudios recientes compártelos (opcional)
                    </p>
                        <div v-if="this.pdfFiles.length>0" class="text-center">
                            <label for="pdfs" class="btn btn-primary" style="width:80%">Agregar más Exámenes</label>
                            <input class="d-none" type="file" id="pdfs" name="pdfs" accept="application/pdf" multiple ref="pdfsRef" @change="handleFileChange('exams/')">
                        </div>
                        <div v-else class="text-center">
                          <label for="pdfs" class="btn btn-primary" style="width:80%">Seleccionar Exámenes</label>
                          <input class="d-none" type="file" id="pdfs" name="pdfs" accept="application/pdf" multiple ref="pdfsRef" @change="handleFileChange('exams/')">
                      </div>
                        <ul class="mt-3">
                            <li v-for="(file,index) in pdfFiles" :key=index>{{ file.file.name }} - {{ file.status }}</li>
                        </ul>
                  <div class="mb-3 form-check">
                    <input required class="form-check-input" type="radio" id="TermsYes" value="Yes" v-model="terms" style="margin-top: 20px;">
                    <label style="margin-top: 17px" class="form-check-label" for="TermsYes">
                        Acepto los 
                        <a href="https://www.dermapp.com/terminos-y-condiciones" target="_blank">términos y condiciones</a>
                    </label>
                  </div>
                  <div class="mb-3 d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary" :disabled="isUploading">Continuar</button>
                  </div>
                </div>               
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid'; // this generates a unique identifier
import algoliasearch from "algoliasearch/lite";
import aa from 'search-insights';
import { ref } from "vue";
import { VueTelInput } from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import useFacebookPixel from '@/composables/useFacebookPixel';

import { onMounted } from 'vue';
import heic2any from 'heic2any';


import Swal from 'sweetalert2';


aa('init', {
  appId: 'Y0B0JKM7OD',
  apiKey: 'b814ff419be43c1b27c93ed61b23b41a',
});



  const client = algoliasearch("Y0B0JKM7OD", "b814ff419be43c1b27c93ed61b23b41a");
  //const index = client.initIndex("Baulderma");
  const index = client.initIndex("Omicron_pharmacy");

  
  export default {
    setup() {
    const { track } = useFacebookPixel();


    onMounted(() => {
    track('Lead', { content_name: 'Start Guest Assessment' });
    track('ViewContent', { content_name: 'Create Guest Assessment' });

    
    });

    const searchQuery = ref("");
    const item = ref("");
    const searchResults = ref([]);
    const searchID = ref("");
    const treatments = ref([]);
    const treatment_item = ref({
        name: '',
        sku: '',
        sku_magistral: '',
        price: null,
        priceIVA: null,
        indications: [],
        is_minimal: 1,
      });

    const performSearch = async () => {
      try {
    const response = await index.search(searchQuery.value, {
      clickAnalytics: true
    });
    searchResults.value = response.hits;
    searchID.value = response.queryID;
  } catch (error) {
    console.error(error);
  }  };

    const selectProduct = (name) => {
      item.value = name;
      searchResults.value = [];
      searchQuery.value = name.name; // Clear search results after selection
    };

    const addTreatment = (addProduct) => {
      if (searchQuery.value && item.value!='') {
          treatments.value.push(item.value);
          treatment_item.value.name = item.value.name;
          treatment_item.value.sku = item.value.ean;
          treatment_item.value.sku_magistral = item.value.code;
          treatment_item.value.price = item.value.price;
          treatment_item.value.priceIVA = item.value.price_iva;
          addProduct(treatment_item.value);
          searchQuery.value = '';
          item.value = '';
        } else{
          treatments.value.push(searchQuery.value);
          treatment_item.value.name = searchQuery.value;
          treatment_item.value.sku = '0000000000';
          treatment_item.value.sku_magistral = '0000000000'
          treatment_item.value.price = 0;
          treatment_item.value.priceIVA = 0;
          addProduct(treatment_item.value);
          searchQuery.value = '';
          item.value = '';
        }
      };

  
   const removeTreatment = (index,current_treatment) => {
        treatments.value.splice(index, 1);
        current_treatment.splice(index,1);
        treatment_item.value = ''
      };


    return { searchQuery, searchResults, performSearch, selectProduct, item, addTreatment, removeTreatment, treatments, treatment_item };
  },

  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName']),
  },

  data() {
    return {
      isPhoneValid: false,
      terms: 'Yes',
      isUploading:false,
      imageFiles: [],
      pdfFiles: [],
      assessmentId: null,
      userId: null,
      assessment: {
        profile_id: 0,
        consultation_reason: '',
        symptoms: [],
        locations: [],
        problem_duration: '',
        problem_evolution: '',
        treatment_history: [],
        current_treatment: [],
        budget: '',
        pregnancy_status: '',
      },
      symptom: '',
      location:'',
      changes: '',
      previous: '',
      current: '',
      limit: '',
      email: '',
      password: '',
      phone: '',
      countryCode: '',
      referral_code: '',
      name:'',
      last_name:'',
      weight:'',
      height:'',
      sex:'',
      birthday: '',
      year: null,
      month: null,
      day: null,
      diseasesHistory:[],
      disease:'',
      allergies:[],
      allergy:'',
      step: 0,
      imageFail: false,
      currentTreatment: '',
      previousTreatment: '',
      HeaderLogo: require('@/assets/dermapp-white.png')
    };
  },

  components: {
    'vue-tel-input': VueTelInput,
  },
  
  methods: {

    openWhatsapp() {
            let url = 'https://api.whatsapp.com/send?phone=528140052978&text=Hola%2C%20necesito%20ayuda%20para%20enviar%20las%20imagenes%20de%20mi%20consulta';
            window.open(url, '_blank');
        },

        async handleFileChange(folder) {
  let selectedFiles = [...event.target.files];
  let new_total = this.imageFiles.length + selectedFiles.length;
  let new_total_pdf = this.pdfFiles.length + selectedFiles.length;
  if (selectedFiles.length > 0) {
    this.isUploading = true;
  }

  for (let i = 0; i < selectedFiles.length; i++) {
    let file = selectedFiles[i];
    if (file.type === 'image/heic') {
      file = await this.convertHeicToJpeg(file);
    }
    const thumbnail = await this.createThumbnail(file);

    let tempFile = {
      file: file,
      status: 'Subiendo...',
      thumbnail: thumbnail,
      progress: 0
    };

    if (folder == 'images/') {
      this.imageFiles.push(tempFile);
      await this.uploadToS3(this.imageFiles[this.imageFiles.length - 1].file, folder, this.imageFiles.length - 1, new_total);
    } else {
      this.pdfFiles.push(tempFile);
      await this.uploadToS3(this.pdfFiles[this.pdfFiles.length - 1].file, folder, this.pdfFiles.length - 1, new_total_pdf);
    }
  }
},

async convertHeicToJpeg(file) {
  const convertedBlob = await heic2any({
    blob: file,
    toType: 'image/jpeg',
  });
  return new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: 'image/jpeg' });
},

async createThumbnail(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement('img');
      img.src = e.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 100;
        canvas.height = 100;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL('image/jpeg'));
      };
    };
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(file);
  });
},

async uploadToS3(file, folder, position, total) {
  const fileExtension = file.name.split('.').pop();
  const newFileName = `${uuidv4()}.${fileExtension}`; // creates a new unique file name
  const fileName = `${folder}${newFileName}`;
  const destination = 'dermapp-documents';
  const availability = 'public-read';

  const formData = new FormData();
  formData.append('destination', destination);
  formData.append('fileName', fileName);
  formData.append('file', file);
  formData.append('availability', availability);

  try {
    await axios.post(`${this.$baseURL}/api/upload-to-s3`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.imageFiles[position].progress = percentCompleted;
      },
    });

    await this.uploadToDB(newFileName, fileExtension, folder, position, total);
  } catch (error) {
    this.imageFail = true;
    this.isUploading = false;
    if (folder == 'images/') {
      this.imageFiles[position].status = 'Cancelado';
    } else {
      this.pdfFiles[position].status = 'Cancelado';
    }
    console.error('Error uploading files to s3:', error);
  }
},

async uploadToDB(fileName, fileExtension, folder, position, total) {
        let fileType = folder === 'images/' ? 0 : 1;
        try {
            const files_data = {
                assessment_id: this.assessmentId,
                file_name: fileName,
                file_extension: fileExtension,
                file_type: fileType,
            }
            await axios.post(`${this.$baseURL}/api/assessment-files`, files_data);

            if(folder == 'images/'){
              this.imageFiles[position].status = 'Completado';
            }
            else{
              this.pdfFiles[position].status = 'Completado';
            }
            if(position+1==total){
              this.isUploading =false;
            }


        } catch (error) {
            console.error('Error creating assessment:', error);
            throw error; // Propagate the error so we can handle it in the calling function
        }
    },

    ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileLastName', 'updateProfileSex', 'updateProfileSubscription', 'updateProfileBirthday'  ]),

    addDisease() {
      if (this.disease!='') {
        this.diseasesHistory.push(this.disease);
        this.disease = '';
      }
    },

    addAllergy() {
      if (this.allergy!='') {
        this.allergies.push(this.allergy);
        this.allergy = '';
      }
    },

    handleUpdate(details) {
      if (typeof details === 'string') {
        this.phone = details.replace(/\s+/g, '');
        let phoneNumber = parsePhoneNumberFromString(this.phone);
        if (phoneNumber && phoneNumber.isValid()) {
          this.countryCode = phoneNumber.country;
          this.isPhoneValid = true;
        } else {
          this.isPhoneValid = false;
        }
      }
    },

    removeDisease(index) {
      this.diseasesHistory.splice(index, 1);
    },

    removeAllergy(index) {
      this.allergies.splice(index, 1);
    },

    addProduct(treatment_item){

    let temp_treatment_item = {
      name: treatment_item.name,
      sku: treatment_item.sku,
      sku_magistral: treatment_item.sku_magistral,
      price: treatment_item.price,
      priceIVA: treatment_item.priceIVA,
      indications: treatment_item.indications,
      is_minimal: treatment_item.is_minimal,
    }

    this.assessment.current_treatment.push(temp_treatment_item);

    treatment_item.name = '';
    treatment_item.sku = '';
    treatment_item.sku_magistral = '',
    treatment_item.price = '';
    treatment_item.priceIVA = '';
    treatment_item.indications = [];
    treatment_item.is_minimal = 1;

    },

    addSymptom() {
      if (this.symptom!='') {
        this.assessment.symptoms.push(this.symptom);
        this.symptom = '';
      }
    },
    removeSymptom(index) {
      this.assessment.symptoms.splice(index, 1);
    },
    addLocation() {
      if (this.location!='') {
        this.assessment.locations.push(this.location);
        this.location = '';
      }
    },
    removeLocation(index) {
      this.assessment.locations.splice(index, 1);
    },
    addCurrentTreatment() {
      if (this.currentTreatment!='') {
        this.assessment.current_treatment.push(this.currentTreatment);
        this.currentTreatment = '';
      }
    },
    removeCurrentTreatment(index) {
      this.assessment.current_treatment.splice(index, 1);
    },
    addPreviousTreatment() {
      if (this.previousTreatment!='') {
        this.assessment.treatment_history.push(this.previousTreatment);
        this.previousTreatment = '';
      }
    },
    removePreviousTreatment(index) {
      this.assessment.treatment_history.splice(index, 1);
    },

      async nextStep() {
    if (this.step < 2) {
      if (this.step == 0 && this.isPhoneValid) {
        this.addDisease();
        this.addAllergy();
        await this.createProfile();
      } else if (this.step == 1) {
        this.addLocation();
        this.addSymptom();
        this.addPreviousTreatment();
        this.addCurrentTreatment();
        await this.createAssessment();
      }
    } else {
      if (this.isUploading) {
        alert('Cargando imagenes, por favor espera');
      } else {
        this.$router.push({ name: 'GuestPaymentMethod', params: { id: this.assessmentId, password: this.password } });
      }
    }
  },

      generateReferralCode() {
      let datePart = Date.now().toString(36);  // unique part based on current time
      let randomPart = Math.random().toString(36).substr(2, 5); // 5 random characters
      return datePart + randomPart;
    },

    formatYear() {
        if (this.year >= 0 && this.year <= 99) {
            if (this.year < 24) {  // Assuming years < 33 belong to 2000s. Adjust based on your use case.
                return '20' + this.padZero(this.year);
            } else {
                return '19' + this.padZero(this.year);
            }
        }
        return this.year;  // Assumes year is already in YYYY format.
    },

    padZero(num) {
        return num.toString().padStart(2, '0');
    },

    async createProfile() {
  try {
    
    const user = {
    email: this.email,
    phone: this.phone,
    country: this.countryCode,
    referral_code: this.generateReferralCode(),
  };

    const responseUser = await axios.post(`${this.$baseURL}/api/create-user`, user);
    this.userId = responseUser.data.user_id;

    if (responseUser.data.status == 200) {

    const profile = {
    user_id: this.userId,
    name: this.name +' '+ this.last_name,
    birthday: this.birthday,
    weight: this.weight,
    height: this.height,
    sex: this.sex,
    diseases_history: this.diseasesHistory,
    allergies: this.allergies,
  };

    const responseProfile = await axios.post(`${this.$baseURL}/api/create-profile`, profile);

    if(responseProfile.data.status==200){
      this.assessment.profile_id = responseProfile.data.profile_id;
      this.step++;
    }

      this.password = this.name.toLowerCase().split(" ", 1) + "123";

      Swal.fire({
        title: '<h3>¡Hola ' + this.name.split(" ", 1) + '!<br>Bienvenido a Dermapp</h3>',
        html: '<div style="text-align: left;">' +
          '<small>usuario: ' + '<br>' + this.email + '<br><br>' +
          'contraseña: ' + '<br>' + this.password + '<br><br>' +
          'Guarda estos datos para ingresar a la plataforma más adelante.</small>' +
          '</div>',
        icon: 'success',
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#0d6efd',
        showCloseButton: true,
      });

      try {
        let automated_email = {
          to: this.email,
          subject: "Bienvenido a Dermapp!",
          template_name: "https://dermapp-documents.s3.amazonaws.com/templates/account_created.png",
          targetURL: `${this.$baseURLPacientes}/signin`,
        };
        await axios.post(`${this.$baseURL}/api/send-email`, automated_email);
      } catch (emailError) {
        console.error("Error sending case sent email:", emailError);
      }
      await this.signUp();

    } else {
      Swal.fire({
        title: '<h3>¡Hola ' + this.name.split(" ", 1) + '!<br> Parece que ya tienes una cuenta</h3>',
        html: '<small style="text-align: center;">' +
          'Por favor da clic en el botón para ingresar a la plataforma' +
          '</small>',
        icon: 'error',
        confirmButtonText: 'Iniciar sesión',
        confirmButtonColor: '#0d6efd',
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'SignIn' });
        }
      });
    }

  } catch (error) {
    console.error('Error creating user:', error);
  }
},


    toSignIn(){
      this.$router.push({ name: 'SignIn'});
    },

    async signUp() {
  try {
    const auth = getAuth();
    const result = await createUserWithEmailAndPassword(auth, this.email, this.password);

    await sendEmailVerification(result.user);


    const user = result.user;

    // Obtén el token de ID
    const idToken = await user.getIdToken();

    localStorage.setItem('Token', idToken);
    localStorage.setItem('Email', this.email);

  } catch (error) {
    this.errorMessage = error.message;
  }
},

      async createAssessment() {

                this.updateEmail(this.email);
                this.updatePhone(this.phone);
                this.updateCountry(this.country);
                this.updateUserId(this.userId);
                this.updateProfileName(this.name.split(' ')[0]);
                this.updateProfileLastName(this.last_name.split(' ')[0]);
                this.updateProfileBirthday(this.birthday);
                this.updateProfileSex(this.sex);

                const updatedAssessment = {
            profile_id: this.assessment.profile_id,
            consultation_reason: this.assessment.consultation_reason,
            symptoms: this.assessment.symptoms,
            locations: this.assessment.locations,
            problem_evolution: this.assessment.problem_evolution,
            treatment_history: this.assessment.treatment_history,
            current_treatment: this.assessment.current_treatment,
            budget: this.assessment.budget,
            problem_duration: this.assessment.problem_duration,
            pregnancy_status: this.assessment.pregnancy_status,
          }
        
        try {
            // Make a POST request to your API endpoint to create the assessment
            const responseAssessment = await axios.post(`${this.$baseURL}/api/create-assessment`, updatedAssessment);
            if (responseAssessment.status === 200) {
              this.step++;

              this.assessmentId=responseAssessment.data.assessment_id;
              
          Swal.fire({
  title: '<h3>¡Felicidades ' + this.name.split(" ",1) +'!<br>Has creado tu primera consulta</h3>',
  html: '<div style="text-align: left;">' + // Container div for left alignment
        '<small>Envía las imágenes de tu problema y completa el pago para recibir tu diagnóstico y tu plan de tratamiento.<br><br>' +
        'Puedes ingresar a la plataforma con tu usuario y contraseña para editar tu consulta o completar el proceso en cualquier momento.<br><br>' +
        '</small>' +
        '</div>',
  icon: 'success',
  confirmButtonText: 'Continuar',
  confirmButtonColor: '#0d6efd',
  showCloseButton: true,

});

                // If the request was successful, save the new assessment id
              try{
            let automated_email = {
              to: this.email,
              subject: "Recibimos tu consulta dermatológica!",
              template_name: "https://dermapp-documents.s3.amazonaws.com/templates/case_sent.png",
              targetURL: `${this.$baseURLPacientes}/signin`,
              }
              axios.post(`${this.$baseURL}/api/send-email`, automated_email);
            }catch{
              console.error("Error sending case sent email");
            }

            } else {
                console.error('Error creating assessment:', responseAssessment);
            }
        } catch (error) {
            console.error('Error creating assessment:', error);
        }
    },

  },

}
</script>

<style scoped>

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 10px;
  padding: 10px;
}

.carousel img {
  scroll-snap-align: start;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10%;
  border: 2px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.carousel .progress {
  width: 100px;
  height: 5px;
  margin-top: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
  overflow: hidden;
}

.carousel .progress-bar {
  height: 5px;
  background-color: #007bff;
  transition: width 0.4s;
}

.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10%;
  margin-right: 10px;
  border: 2px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}


body {
  background: #38b6ff;
}
.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}
.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.my-confirm-button {
  background-color: #0d6efd !important; /* Example: blue */
  color: white; /* Text color */
}

  
  .stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  width: 20px;
  height: 20px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #E0E0E0; /* gray color for inactive steps */
  display: inline-block;
  font-size: 14px;           /* font size for the step number */
  color: #777;               /* font color for inactive steps */
  line-height: 20px;         /* centers the number vertically */
  text-align: center;        /* centers the number horizontally */
}

.step.active {
  background-color: #3498db; /* blue color for the active step */
  color: #fff;               /* font color for the active step */
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

</style>
