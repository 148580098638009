<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
      </div>
      <nav class="d-flex align-items-center justify-content-end" style="width: 100%; text-align:right">
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px;width:auto;margin-right:20px;border: 2px solid white;border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ this.credits }}
        </button> 
        <router-link v-if='this.isDataLoaded' :to="{ name: 'EditUser', params: { id: userId }}">
          <img :src="User" alt="User" class="user" style="height:40px;width:auto;margin-bottom:-1px"/>
        </router-link>
      </nav>
    </header>
    
    <div class="container">
      <div class="row justify-content-center">
        <div v-if='this.isDataLoaded' class="col-md-8">
          <router-link v-if="this.orders.length>0" to="/orders-dashboard" class="no-link-style">
            <div class="card mb-2 d-flex align-items-center flex-row">
              <img :src="UserOrders" alt="UserOrders" class="user-orders" style="height: 50px; width: auto; margin: 10px;" />
              <h5 class="mb-4 mt-4 ml-3">Mis pedidos</h5>
            </div>
          </router-link>
          <!-- Adjusted container to have a fixed height and be scrollable -->
          <div class="card mb-2">
            <div class="mt-2">
              <h5 class="text-center mt-2">Mis Perfiles</h5>
              <img :src="UserProfiles" alt="UserProfiles" class="user-profiles" style="height: 50px; width: auto; margin: 10px;" />

            </div>
            <div class="card-body"  style="height: 46vh; overflow-y: auto;">
              <!-- Profile cards will be here and scrollable within the container -->
              <router-link 
                v-for="profile in profiles" 
                :key="profile.id" 
                :to="{ name: 'PatientProfile', params: { id: profile.id }}"
                class="card mb-2 d-block text-decoration-none" 
                style="color: inherit;">
                <div class="card-body">
                  <strong class="card-title">{{ profile.name }}</strong>
                  <p class="card-text">
                    {{ parseSex(profile.sex) }} - {{ calculateAge(profile.birthday) }} años
                  </p>
                </div>
              </router-link>
              </div>
              <div class="card-body d-flex justify-content-center align-items-center">
                <div v-if="this.new_message == 1" style="margin-right:20px">
                  <router-link :to="{ name: 'UserMessages', params: { id: this.userId }}">
                    <img :src="Messages" alt="Messages" class="messages" style="height: 40px; width: auto;" />
                  </router-link>
                </div>
                <div v-else style="margin-right:20px">
                  <router-link :to="{ name: 'UserMessages', params: { id: this.userId }}">
                    <img :src="NewMessages" alt="NewMessages" class="new-messages" style="height: 40px; width: auto;" />
                  </router-link>
                </div>
                <div style="margin-left:20px">
                  <img @click="addNewProfile()" :src="AddProfile" alt="AddProfile" class="add-profile" style="height: 40px; width: auto;" />
                </div>
              </div>
              
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


  
  <script>
  import { mapActions, mapState } from 'vuex';
  import axios from 'axios';
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
  import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';

export default {
  setup() {
    const { track } = useFacebookPixel();

    

    onMounted(() => {
      track('ViewContent', { content_name: 'Patient dashboard' });

    });
    return {};

  },
    data() {
      return {
        profiles: [],
        orders: [],
        isDataLoaded: false,
        HeaderLogo: require('@/assets/dermapp-white.png'),
        User: require('@/assets/edit-user-icon.webp'),
        AddProfile: require('@/assets/add-profile-icon.png'),
        Messages: require('@/assets/message-icon.png'),
        NewMessages: require('@/assets/new-message-icon.png'),
        UserOrders: require('@/assets/user-orders.webp'),
        UserProfiles: require('@/assets/user-profiles.webp'),


        new_message: null,

      };
    },
    computed: {
      ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName', 'primaryName', 'primaryBirthday']),
    },

    async mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });
  


      const token = localStorage.getItem('Token');
      const email = localStorage.getItem('Email');
  // Fetch the user data
      axios.get(`${this.$baseURL}/api/user-data-v2`, {
      params: {
        email: email || this.$route.query.email,
        page: 'Patient Dashboard'
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((response) => {
      
      const data = response.data.data;
      axios.get(`${this.$baseURL}/api/user-orders`, {
      params: {
        user_id: data.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        },
    }).then((orders_response) => {
      this.orders = orders_response.data.orders

    });
      this.new_message = data.new_message;
      this.updateUserId(data.id);
      this.updateEmail(data.email);
      this.updatePhone(data.phone, data.country);
      this.updateCountry(data.country);
      this.updateCredits(data.credits);
      this.updateReferralCode(data.referral_code);
      this.getProfiles();
      this.isDataLoaded = true;
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });

    
},
    methods: {

      ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription', 'updatePrimaryBirthday'  ]),

      calculateAge(birthday) {
    const birthdate = new Date(birthday);
    const ageDifMs = Date.now() - birthdate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  },

      parseSex(sex){
        if(sex=='Male'){
          return 'Masculino';
        } else {
          return 'Femenina';
        }
      },
      
      async getProfiles() {
        const token = localStorage.getItem('Token');
        const email = localStorage.getItem('Email');
        try {
          const response = await axios.get(`${this.$baseURL}/api/get-profiles`, {
            params: {
              user_id: this.userId,
              email: email,
            },
            headers: {
                  'Authorization': `Bearer ${token}`
      }
          });

          this.profiles = response.data.data;
          this.updatePrimaryBirthday(this.profiles[0].birthday);
  
        } catch (error) {
          console.error('Error fetching profiles:', error);
        }
      },

      addNewProfile() {
        this.$router.push({ name: 'CreateProfile' });
      },
    },

  };
  </script>
  
  <style scoped>

.header {
    margin-top: -5px;
    position: relative;
    width: 100%;
    height: auto;
    background: #36b5ff;
    z-index: 1;
  }

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-link-style {
  text-decoration: none;
  color: inherit; /* Ensure that the text color remains unchanged */
}

  </style>
  