<template>
      <header class="header d-flex align-items-flex-start" style="width: 100%; padding-left: 20px; margin-top: -10px;">
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:60px;"/>
      </header>
      <div class="container text-white" style="height: 100vh; width: 100vw; text-align: left;">
        <p>
          I- RESPONSABLE DEL TRATAMIENTO DE LOS DATOS PERSONALES
<br><br>
MONTERREY HEALTHTECH SOLUTIONS, S.A.P.I. DE C.V., comercialmente conocida como Dermapp (en lo sucesivo Dermapp) es una empresa constituida bajo las leyes mexicanas con domicilio ubicado en Av Igacio Morones Prieto 4500, Col Jesus M Garza, San Pedro Garza Garcia, Nuevo Leon, CP 66238 y en cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP), su Reglamento y los Lineamientos del Aviso de Privacidad, pone a disposición de sus Usuarios el presente Aviso de Privacidad. Los lineamientos y condiciones contenidas en el presente son aplicables a los Datos Personales de todos nuestros Usuarios que sean recabados y tratados a nombre de Dermapp a través del sitio web www.dermapp.com, aplicación móvil denominada dermapp o cualquier otro sitio operado por Dermapp. Este Aviso de Privacidad no es aplicable a ninguna aplicación o software de terceros que se integre con los Servicios a través de las plataformas de Dermapp.
<br><br>
II- FINALIDAD DEL TRATAMIENTO DE DATOS PERSONALES DE NUESTROS CLIENTES.
<br><br>
Es posible que se le solicite que proporcione sus Datos Personales en cualquier momento que esté en contacto con Dermapp. El Usuario no está obligado a proporcionar los Datos Personales que Dermapp solicita, pero, si elige no hacerlo, en muchos casos Dermapp no podrá proporcionar el servicio, ni responder a consultas que el Usuario pueda tener.
<br><br>
Dermapp hace de su conocimiento que las Finalidades para las cuales son recabados y tratados sus Datos Personales:
<br><br>
Proveer el servicio de análisis de lesiones en la piel.<br>
Procesar los resultados de los análisis de nuestros Usuarios.<br>
Permitir al Usuario tener un seguimiento de la lesión en la piel.<br>
Cumplir con los Términos y Condiciones establecidos por Dermapp.<br>
Personalizar nuestra atención al Usuario para brindar servicios de mayor calidad.<br>
Proceder con el proceso de cobranza y facturación.<br>
Atender y responder los procesos de aclaraciones, reembolsos y quejas.<br>
Cumplir con la legislación aplicable en la República Mexicana.<br>
Suministrar medicamentos alopáticos o magistrales a nuestros pacientes con apoyo de red de farmacias con las que mantenemos alianza.<br>
Se utilizarán los Datos Personales en forma disociada para sesiones académicas, de investigación, diagnóstico, tratamientos, etc., así como para generar estadísticas de los resultados de análisis de las lesiones para nutrir, ampliar y mejorar el funcionamiento de Dermapp.<br>
Adicional a lo anterior, podemos realizar el tratamiento de los Datos Personales para las siguientes Finalidades secundarias (no necesarias para brindar el servicio):
<br><br>
Enviar promociones, publicidad y encuestas de satisfacción a través diversos medios.<br>
En caso de que Usted no desee que sus Datos Personales se utilicen para estos fines secundarios, deberá enviar la notificación en un periodo no mayor a 20 (veinte) días hábiles al correo de soporte@dermapp.com, indicando cuales son los fines secundarios a los que se opone la utilización de sus Datos Personales. La negativa para el uso de sus Datos Personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios que solicita o contrata con Dermapp
<br><br>
III- DATOS PERSONALES QUE RECABA Y TRATA DERMAPP.
<br><br>
Para llevar a cabo las finalidades anteriormente descritas, Dermapp podrá utilizar los siguientes Datos Personales de sus Usuarios:
<br><br>
Edad, sexo.<br>
Correo electrónico<br>
Teléfono<br>
En caso de conectarse a través de la cuenta de Facebook de Usuario, aquellos Datos Personales de identificación.<br>
Imágenes de la lesión en la piel a analizar.<br><br>
IV- TRANSFERENCIA DE DATOS PERSONALES.
<br><br>
Sus Datos Personales serán manejados por personal de Dermapp quien funge como persona responsable del tratamiento, a efecto de ofrecer los servicios a sus Usuarios. Sin embargo, también se prevé la transferencia a nuestra red de farmacias para efectos de suministro de medicamentos e insumos para la salud, incluyendo además de aquellas operaciones necesarias para el cumplimiento de obligaciones legales, judiciales y fiscales.
<br><br>
Le informamos que Dermapp como responsable de sus Datos Personales, se reserva el derecho de compartir su información a sociedades controladoras, subsidiarias o afiliadas, sin necesidad de su consentimiento toda vez que dicha transferencia será realizada en términos del artículo 37 de la LFPDPPP.
<br><br>
V- SEGURIDAD Y PROTECCIÓN DE LOS DATOS PERSONALES
<br><br>
Para prevenir el acceso no autorizado a sus datos personales y con el fin de asegurar que la información sea utilizada para los fines establecidos en este Aviso de Privacidad, hemos establecido procedimientos internos que permiten resguardar sus Datos Personales bajo estrictas medidas de seguridad administrativas, técnicas y físicas las cuales han sido implementadas con el objeto de proteger los mismos contra daño, perdida, alteración, destrucción, uso o divulgación no autorizados de sus datos, permitiéndonos tratarlos debidamente. Únicamente el personal autorizado, podrá participar en el tratamiento de sus Datos Personales. El personal autorizado tiene prohibido permitir el acceso de personas no autorizadas y utilizar sus Datos Personales para fines distintos a los establecidos en el presente Aviso de Privacidad.
<br><br>
VI- PROCEDIMIENTO PARA EJERCITAR SUS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN (ARCO).
<br><br>
Nuestros Usuarios tienen derecho a:
<br><br>
conocer qué Datos Personales recabamos, para qué los utilizamos y las condiciones del uso que les damos (Acceso);<br>
solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación);<br>
solicitar que eliminemos sus Datos Personales de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación) y/o;<br>
oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.<br>
Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del correo electrónico soporte@dermapp.com, el procedimiento y requisitos que deberá contener su solicitud de conformidad con lo dispuesto en la Ley y su Reglamento, son los siguientes:
<br><br>
Nombre y Domicilio, si no se incluye la dirección se dará por no recibida la solicitud.<br>
El documento que acredite su identidad. (Copia de identificación oficial vigente.).<br>
La descripción clara y precisa de los Datos Personales a los que desea acceder, rectificar, cancelar u oponerse.<br>
Descripción de otros elementos que faciliten la localización de sus Datos Personales<br>
Los documentos deberán ser escaneados y adjuntados al correo electrónico para verificar la veracidad de los mismos.<br>
<br><br>
Para conocer información adicional respecto a el procedimiento, requisitos y plazos del ejercicio de los derechos ARCO puedes ponerte en contacto al correo electrónico soporte@dermapp.com.
<br><br>
VII- REVOCACIÓN DEL CONSENTIMIENTO PARA EL USO DE SUS DATOS PERSONALES
<br><br>
Nuestros Usuarios pueden revocar el consentimiento que, en su caso, haya otorgado a Dermapp para el tratamiento de sus Datos Personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus Datos Personales. Asimismo, los Usuarios deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros. Para revocar su consentimiento deberá presentar su solicitud a través del correo electrónico soporte@dermapp.com.
<br><br>
VIII- EL USO DE TECNOLOGÍAS DE RASTREO EN NUESTRO PORTAL DE INTERNET
<br><br>
El Usuario reconoce y acepta que Dermapp podrá utilizar un sistema de seguimiento y reproducción mediante la utilización de cookies (las "Cookies") y web beacon (“web beacon”). Las cookies son archivos temporales que se instalan en su equipo y el web beacon es un código o imagen electrónica transparente, ambos permiten a Dermapp medir patrones de tráfico en el Sitio, así como conocer el comportamiento y la demografía de los Usuarios, con el fin de analizar las páginas navegadas y las búsquedas realizadas, para mejorar la oferta comercial de Dermapp, mostrar publicidad, noticias de interés, personalizar contenidos, presentación y servicios.
<br><br>
Los Datos Personales que se obtienen mediante las Cookies y el web beacon, son:
<br><br>
Identificadores, nombres de usuario y contraseñas de una sesión.<br>
Tipo de navegador.<br>
Tipo de sistema operativo.<br>
Fecha y hora del inicio y final de una sesión de un Usuario.<br>
Dermapp hace de su conocimiento que la instalación, permanencia y existencia de las cookies puede ser cancelada y/o eliminada de su equipo cuando así lo desee, consultando la sección de Ayuda del navegador que utilice para acceder a Internet.
<br><br>
IX- MODIFICACIONES AL AVISO DE PRIVACIDAD.
<br><br>
Nos comprometemos a mantener informados a nuestros Usuarios sobre las modificaciones, cambios o actualizaciones del presente Aviso de Privacidad, derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas, a través de su publicación en la Página de Dermapp: www.dermapp.com
<br><br>
Fecha de última actualización: Enero de 2024
       </p>
    </div>
  </template>

<script>
export default {
  data() {
    return {
      HeaderLogo: require('@/assets/dermapp-white.webp')
    };
  },
};
</script>